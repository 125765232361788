import { h, Component } from '@app/utils'
import { isDefined } from '@app/core'
import { MessagePopup } from '@app/elements'

import './index.scss'

const AdminFormVerifyWithQuery = Component(
    {
        value: '',
        descriptionValue: '',
        errorValue: '',
        name: '',
        readonly: false,
        verifyQuery: null,
        verifyParam: null,
        verifyParamOptParams: null,
        verifyLabel: '',
        verifySuccess: '',
        messageKind: 'success',
        verifyValidation: null,
        forceButton: false,
        verified: false,
        originalDocument: null,
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            console.log('WITH QUERY : ', props)
            if (props.oninput) {
                state.sendToParent = props.oninput
            }
            if (props.document.validation) {
                actions.setVerifyValidation(props.document.validation)
            }
            if (props.document.verifyQuery) {
                actions.setVerifyQuery(props.document.verifyQuery)
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            let getValue = null
            // props.document.value = {"date":"2019-11-08 12:44:42.157000","timezone_type":3,"timezone":"Europe/Paris"};
            // props.document.value = [{"name":"rpps","id":"10002713369","key":null,"secret":null}];
            if (props.document.value) {
                if (isDefined(props.document.searchedValue)) {
                    console.log('WITH QUERY : ', props.document.searchedValue)
                    if (
                        isDefined(
                            props.document.value[props.document.searchedValue]
                        )
                    ) {
                        getValue =
                            props.document.value[props.document.searchedValue]
                    } else if (Array.isArray(props.document.value)) {
                        for (var entry in props.document.value) {
                            if (
                                props.document.value[
                                    entry
                                ].name.toLowerCase() ===
                                props.document.searchedValue.toLowerCase()
                            ) {
                                if (props.document.value[entry].id) {
                                    getValue = props.document.value[entry].id
                                } else {
                                    getValue = props.document.value[entry].value
                                }
                                break
                            }
                        }
                    }
                } else {
                    getValue = props.document.value
                }
            }
            if (isDefined(props.document.readonly)) {
                actions.setReadonly(props.document.readonly)
            }
            console.log(
                'updateASPatientSearch > selectSearchResult > getValue : ',
                props,
                getValue
            )
            actions.setName(props.name)
            if (props.name && props.name.indexOf('blocked') > -1) {
                if (
                    props.document.value === '' ||
                    props.document.value === null ||
                    props.document.value === false
                ) {
                    actions.setValue('Compte OK')
                } else if (isDefined(props.document.value.date)) {
                    actions.setValue('Compte bloqué')
                    actions.setForceButton(true)
                } else {
                    actions.setValue('Le statut du compte est inconnu')
                }
            } else if (props.name && props.name.indexOf('nabla') > -1) {
                //
            } else {
                if (isDefined(getValue)) {
                    actions.setValue(getValue)
                    if (isDefined(getValue, true, true)) {
                        actions.setReadonly(true)
                    }
                }
            }
            if (props.document.verified) {
                actions.setVerified(props.document.verified)
            }
            if (props.document.verifyLabel) {
                actions.setVerifyLabel(props.document.verifyLabel)
            }
            if (props.document.verifyParamOptParams) {
                actions.setVerifyParamOptParams(
                    props.document.verifyParamOptParams
                )
            }
            if (props.document.verifySuccess) {
                actions.setVerifySucccess(props.document.verifySuccess)
            }
            if (props.document.verifyParam && props.original) {
                let verifParam = props.original[props.document.verifyParam]
                actions.setVerifyParam(verifParam)
            }
            if (props.original) {
                actions.setOriginalDocument(props.original)
            }
        },
        updateField: () => (state, actions) => {
            if (typeof state.sendToParent === 'function') {
                state.sendToParent({
                    name: state.name,
                    value: state.value,
                    verified: state.verified,
                })
            }
        },
        updateValue: (e) => (state, actions) => {
            console.log('updateValue : ', e)
            actions.setValue(e.target.value)
            if (state.verified !== false) {
                actions.setVerified(false)
            }
            if (state.errorValue !== '') {
                actions.setErrorValue('')
            }
            actions.updateField()
        },
        verifyField: () => (state, actions) => {
            let verificationErrorMessage = null
            let verificationLength = null,
                verificationType = null
            if (isDefined(state.verifyValidation)) {
                verificationErrorMessage =
                    state.verifyValidation.verifiedMessage
                if (isDefined(state.verifyValidation.type)) {
                    let verificationKind =
                        state.verifyValidation.type.split('/')
                    if (verificationKind.length === 2) {
                        verificationLength = verificationKind[1]
                        verificationType = verificationKind[0]
                    }
                }
            }
            //
            if (isDefined(state.verifyQuery)) {
                let queryParam = null
                if (isDefined(state.verifyParam)) {
                    queryParam = state.verifyParam
                } else {
                    queryParam = state.value
                }
                if (
                    verificationType === 'exact-length' &&
                    queryParam.length !== +verificationLength
                ) {
                    actions.setErrorValue(state.verifyValidation.message)
                    return false
                }
                //
                let optParams = []
                if (
                    state.verifyParamOptParams &&
                    Array.isArray(state.verifyParamOptParams) &&
                    state.verifyParamOptParams.length > 0
                ) {
                    for (let params in state.verifyParamOptParams) {
                        if (
                            isDefined(
                                state.originalDocument[
                                    state.verifyParamOptParams[params]
                                ]
                            )
                        ) {
                            optParams.push(
                                state.originalDocument[
                                    state.verifyParamOptParams[params]
                                ]
                            )
                        }
                    }
                }
                //
                state.verifyQuery(queryParam, ...optParams).then((res) => {
                    console.log(
                        'VERIFY : ',
                        res,
                        verificationErrorMessage,
                        state.verifyValidation
                    )
                    let verified = true
                    if (
                        isDefined(res.data) &&
                        Array.isArray(res.data) &&
                        isDefined(res.data[0].count)
                    ) {
                        if (
                            (res.data[0].count.length === 0 &&
                                res.data[0].result.length === 0) ||
                            res.data.message === 'ok'
                        ) {
                            verified = false
                        }
                    }
                    //
                    if (
                        verified &&
                        isDefined(res.data) &&
                        !isDefined(res.data.code) &&
                        (res.data.length > 0 ||
                            isDefined(res.data.id) ||
                            res.data.message === 'ok')
                    ) {
                        console.log('VERIFY : !! ', res)
                        //
                        actions.setVerified(true)
                        actions.updateField()
                        if (state.verifySuccess !== '') {
                            actions.setMessageContent(state.verifySuccess)
                            actions.displaySuccessMessage(true)
                        }
                    } else if (isDefined(verificationErrorMessage)) {
                        console.log('VERIFY : ?? ', res)
                        actions.setVerified(false)
                        actions.updateField()
                        actions.setErrorValue(verificationErrorMessage)
                    }
                })
            }
        },
        setValue: (newState) => (state) => ({
            value: newState,
        }),
        setVerified: (newState) => (state) => ({
            verified: newState,
        }),
        setVerifyQuery: (newState) => (state) => ({
            verifyQuery: newState,
        }),
        setVerifyParam: (newState) => (state) => ({
            verifyParam: newState,
        }),
        setVerifyLabel: (newState) => (state) => ({
            verifyLabel: newState,
        }),
        setVerifyParamOptParams: (newState) => (state) => ({
            verifyParamOptParams: newState,
        }),
        setVerifySucccess: (newState) => (state) => ({
            verifySuccess: newState,
        }),
        setReadonly: (newState) => (state) => ({
            readonly: newState,
        }),
        setForceButton: (newState) => (state) => ({
            forceButton: newState,
        }),
        setDescriptionValue: (newState) => (state) => ({
            descriptionValue: newState,
        }),
        setErrorValue: (newState) => (state) => ({
            errorValue: newState,
        }),
        setVerifyValidation: (newState) => (state) => ({
            verifyValidation: newState,
        }),
        displaySuccessMessage: (newState) => (state) => ({
            messageActive: newState,
        }),
        setMessageContent: (newState) => (state) => ({
            messageContent: newState,
        }),
        setName: (newState) => (state) => ({
            name: newState,
        }),
        setOriginalDocument: (newState) => (state) => ({
            originalDocument: newState,
        }),
    },
    (state, actions) => (props, children) => (
        <div key={props.key}>
            {state.messageActive && (
                <MessagePopup
                    kind={state.messageKind}
                    active={'active'}
                    duration={3000}
                    durationCallback={() =>
                        actions.displaySuccessMessage(false)
                    }
                    message={state.messageContent}
                ></MessagePopup>
            )}
            <input
                type="text"
                name={state.name}
                value={state.value}
                oninput={actions.updateValue}
                readonly={state.readonly}
            />
            {((state.value !== '' && state.readonly !== true) ||
                state.forceButton) && (
                <div
                    class="btzForm-btzFormGroup btzForm-btzFooter"
                    style="float: right;margin: 0 0 0 15px;"
                >
                    <div class="btzButtonWrapper">
                        <button
                            class="btn btn-flat btn-primary"
                            onclick={actions.verifyField}
                        >
                            {state.verifyLabel}
                        </button>
                    </div>
                </div>
            )}
            {state.errorValue !== '' && (
                <p class="ovr-verify-info ovr-verify-error">
                    {state.errorValue}
                </p>
            )}
            {state.descriptionValue !== '' && (
                <p class="ovr-verify-info">{state.descriptionValue}</p>
            )}
        </div>
    ),
    'adminformverifywithquery'
)

export { AdminFormVerifyWithQuery }
