import { t, h, Component } from '@app/utils'

import { User, Card, Corpo } from '@app/api'
import {
    Form,
    Button,
    AdminFormForumTopic,
    AdminFormDropDown,
    AdminFormLinkedDropDown,
    AdminFormLinkedSelects,
    AdminFormCheckBox,
    AdminFormRadio,
    AdminFormWithDropDownToDiv,
    AdminFormWithDynInputToDiv,
    AdminFormWithInputToTextarea,
    AdminFormBlockAsTab,
    AdminFormBlockAsTabLang,
    AdminFormMedia,
    AdminFormPictures,
    AdminFormDate,
    AdminFormListing,
    MessagePopup,
    AdminFormHelp,
    AdminFormArrayOfEvalMaker,
    AdminFormTeams,
    AdminFormScenarios,
    AdminFormVerifyWithQuery,
    AdminFormDisplayFromQuery,
    AdminFormArrayOfOrderedLinked,
} from '@app/elements'
import {
    allRoutes,
    isInteger,
    isFloat,
    deepCopy,
    addCSRFToken,
    createQueryMessage,
    isDefined,
    castToNumberOfFloat,
    getQueryVariable,
} from '@app/core'
import { settings } from '@app/core/settings'
import * as constants from '@app/constants'
import './index.scss'
import { isAlphaNum } from '@app/core/tools/isAlphaNum'
import { isValidPhone, isValidPhoneLength } from '@app/core/tools/isValidPhone'
import { isValidEmail } from '@app/core/tools/isValidEmail'

const { APP_FRONT_WEB_URL } = settings

Object.size = function (obj) {
    var size = 0,
        key
    for (key in obj) {
        if (Object.getOwnPropertyDescriptor(obj, key) !== undefined) size++
    }
    return size
}

const AdminForm = Component(
    {
        document: null,
        target: '',
        secondtarget: '',
        backUrl: '',
        previousPage: '',
        formIsSubmitting: false,
        formSubmitted: false,
        editType: null,
        kpiList: [],
        dataTags: [],
        ciqualFamily: [],
        coachsList: [],
        compareList: [],
        timezones: [],
        error: '',
        secondForms: null,
        originaldocument: null,
        messageActive: false,
        messageKind: 'success',
        messageContent: 'default',
        nMedia: 0,
        redirectAfterFormSended: null,
        refId: null,
        refSId: null,
        refSSId: null,
        refType: null,
        previewDisplayed: false,
        displayedLang: 'fr',
        previewEnable: false,
        match: null,
        overridecustomer: 'theraflow',
        searchbar: {
            value: '',
            results: null,
            selected: null,
        },
    },
    {
        onComponentCreate: (props) => (state, actions) => {
            if (props.originaldocument) {
                actions.setOriginalDocument(props.originaldocument)
            }
            // if (props.refId) {
            //     actions.setRefId(props.refId);
            // }
            // if (props.refSId) {
            //     actions.setRefSId(props.refSId);
            // }
            // if (props.refSSId) {
            //     actions.setRefSSId(props.refSSId);
            // }
            if (props.editType) {
                actions.setEditType(props.editType)
            }
            if (props.previousPage) {
                actions.setPreviousPage(props.previousPage)
            }
            if (props.match) {
                actions.setMatch(props.match)
            }
            let overridecustomer = localStorage.getItem('overridecustomer')
            if (overridecustomer) {
                actions.setOverridecustomer(overridecustomer)
            }
            if (props.document) {
                let document = deepCopy(props.document.form || props.document) // to survey, deepCopy is new...

                if (props.document.preview) {
                    actions.setPreviewEnable(true)
                }
                // to survey, made for daily flow email, that dont exist in settings, and must be true when saving first time
                for (var entry in document) {
                    if (
                        isDefined(document[entry]) &&
                        (document[entry].value === '' ||
                            (Array.isArray(document[entry].value) &&
                                document[entry].value.length === 0)) &&
                        isDefined(document[entry].defaultValue)
                    ) {
                        document[entry].value = document[entry].defaultValue
                    }
                }
                //

                if (isDefined(document.langs)) {
                    let langs = document.langs.value,
                        parsedLangs = {}
                    for (var ll in langs) {
                        for (var rl in constants.custo.LANGUAGES) {
                            if (constants.custo.LANGUAGES[rl].key === ll) {
                                parsedLangs[ll] = langs[ll]
                            }
                        }
                    }
                    document.langs.value = parsedLangs
                }
                if (
                    isDefined(document.type) &&
                    isDefined(document.parent) &&
                    document.parent.value !== ''
                ) {
                    // for establishment, if parent is defined, document is necessarily a child
                    if (document.type.type === 'boolean') {
                        document.type.value = 0
                    }
                }
                // actions.setDocument(document);
                actions.parseASDoctor(document)
            }
            if (props.redirectedAfterFormSended) {
                actions.setRedirectAfter(props.redirectedAfterFormSended)
            }

            //
            let nOptQueries = 0
            let filteredForm = Object.keys(props.document).map(
                (i) => props.document[i]
            )
            //
            let filteredFormKPI = filteredForm.filter(
                (f) =>
                    isDefined(f) &&
                    isDefined(f.type) &&
                    (f.type === 'kpi' || f.neededApi === 'kpi')
            )
            if (filteredFormKPI.length > 0) {
                if (state.kpiList.length === 0) {
                    nOptQueries++
                    Card.getKPI().then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.setKPIList(res.data)
                        }
                        nOptQueries--
                        if (nOptQueries <= 0) {
                            actions.initializeAdminForm(props)
                        }
                    })
                }
            }
            let filteredFormDATATAGS = filteredForm.filter(
                (f) =>
                    isDefined(f) && isDefined(f.type) && f.type === 'datatags'
            )
            if (filteredFormDATATAGS.length > 0) {
                if (state.dataTags.length === 0) {
                    nOptQueries++
                    Card.getDataTags().then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.setDataTags(res.data)
                        }
                        nOptQueries--
                        if (nOptQueries <= 0) {
                            actions.initializeAdminForm(props)
                        }
                    })
                }
            }
            let filteredFormCIQUAL = filteredForm.filter(
                (f) =>
                    isDefined(f) &&
                    isDefined(f.type) &&
                    f.type === 'ciqualfamily'
            )
            if (filteredFormCIQUAL.length > 0) {
                if (state.ciqualFamily.length === 0) {
                    nOptQueries++
                    Card.getCiqualFamily().then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.setCiqualFamily(res.data)
                        }
                        nOptQueries--
                        if (nOptQueries <= 0) {
                            actions.initializeAdminForm(props)
                        }
                    })
                }
            }
            let filteredFormCOACH = filteredForm.filter(
                (f) => isDefined(f) && isDefined(f.type) && f.type === 'coach'
            )
            if (filteredFormCOACH.length > 0) {
                if (state.coachsList.length === 0) {
                    nOptQueries++
                    Card.getCoachs().then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.setCoachs(res.data)
                        }
                        nOptQueries--
                        if (nOptQueries <= 0) {
                            actions.initializeAdminForm(props)
                        }
                    })
                }
            }
            let filteredFormCOMPARE = filteredForm.filter(
                (f) =>
                    isDefined(f) &&
                    isDefined(f.type) &&
                    f.type === 'corpocompare'
            )
            if (filteredFormCOMPARE.length > 0) {
                if (state.compareList.length === 0) {
                    nOptQueries++
                    Corpo.getChallenges().then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.setCompareList(res.data)
                        }
                        nOptQueries--
                        if (nOptQueries <= 0) {
                            actions.initializeAdminForm(props)
                        }
                    })
                }
            }

            if (nOptQueries <= 0) {
                actions.initializeAdminForm(props)
            }
        },
        initializeAdminForm: (props) => (state, actions) => {
            if (props.target) {
                actions.setTarget(props.target)
            }
            if (props.secondtarget) {
                actions.setSecondTarget(props.secondtarget)
            }
            if (props.backUrl) {
                actions.setBackUrl(props.backUrl)
            }
            actions.onComponentUpdate(props)
        },
        onComponentUpdate: (props) => (state, actions) => {
            let toValidDocument = props.document.form || props.document
            if (
                isDefined(toValidDocument.type) &&
                isDefined(toValidDocument.parent) &&
                toValidDocument.parent.value !== ''
            ) {
                // for establishment, if parent is defined, document is necessarily a child
                if (toValidDocument.type.type === 'boolean') {
                    toValidDocument.type.value = 0
                }
            }
            // actions.setDocument(deepCopy(props.document.form || props.document));
            if (toValidDocument) {
                actions.setDocument(
                    deepCopy(actions.updateValidations(toValidDocument))
                ) // to survey, deepCopy is new...
            }
            if (props.refId) {
                actions.setRefId(props.refId)
            } else {
                actions.setRefId(null)
            }
            if (props.refSId) {
                actions.setRefSId(props.refSId)
            } else {
                actions.setRefSId(null)
            }
            if (props.refSSId) {
                actions.setRefSSId(props.refSSId)
            } else {
                actions.setRefSSId(null)
            }
        },
        searchASDoctor: (e) => (state, actions) => {
            if (e !== undefined) {
                e.preventDefault()
                e.stopPropagation()
            }
            //
            if (isDefined(state.searchbar.value, true, true)) {
                User.getNursesAS(state.searchbar.value).then((res) => {
                    let result = res.data
                    if (Array.isArray(result)) {
                        result = res.data[0].result
                    } else {
                        result = [result]
                    }
                    if (
                        Array.isArray(result) &&
                        (!isDefined(result[0]) ||
                            !isDefined(result[0]['Identifiant PP']))
                    ) {
                        result = []
                    }
                    //
                    let searchbar = state.searchbar
                    searchbar.results = result
                    actions.updateSearchBar(searchbar)
                    //
                    document.querySelector('[name="searchbar"]').focus()
                })
            }
        },
        parseASDoctor: (doctor) => (state, actions) => {
            let isRPPS = null
            if (
                isDefined(doctor.ssos) &&
                isDefined(doctor.ssos.value) &&
                Array.isArray(doctor.ssos.value)
            ) {
                isRPPS = doctor.ssos.value.filter((sso) => sso.name === 'rpps')
            }
            if (doctor.id && doctor.id.value && doctor.timezone) {
                doctor.timezone.readonly = true
            }
            if (isDefined(isRPPS) && isRPPS.length > 0) {
                doctor.lname.readonly = true
                doctor.fname.readonly = true
                doctor.practitionerEmail.readonly = true
                doctor.practitionerProfession.readonly = true
                doctor.practitionerQualification.readonly = true
                //
                if (isDefined(doctor.practitionerSpecialty.value)) {
                    doctor.practitionerQualification.forceHidden = true
                    doctor.practitionerQualification.readonly = false
                    //
                    doctor.practitionerSpecialty.forceHidden = false
                    doctor.practitionerSpecialty.readonly = true
                } else if (isDefined(doctor.practitionerQualification.value)) {
                    doctor.practitionerQualification.forceHidden = false
                    doctor.practitionerQualification.readonly = true
                    //
                    doctor.practitionerSpecialty.forceHidden = true
                    doctor.practitionerSpecialty.readonly = false
                } else {
                    doctor.practitionerQualification.forceHidden = false
                    doctor.practitionerQualification.readonly = false
                    doctor.practitionerSpecialty.forceHidden = true
                }
                if (isDefined(doctor.practitionerExerciseMode.value)) {
                    doctor.practitionerExerciseMode.forceHidden = false
                }
                //
            } else {
                if (isDefined(doctor.lname)) {
                    doctor.lname.readonly = false
                }
                if (isDefined(doctor.fname)) {
                    doctor.fname.readonly = false
                }
                if (isDefined(doctor.practitionerEmail)) {
                    doctor.practitionerEmail.readonly = false
                }
                if (isDefined(doctor.practitionerProfession)) {
                    doctor.practitionerProfession.readonly = false
                }
                if (isDefined(doctor.practitionerQualification)) {
                    doctor.practitionerQualification.readonly = false
                }

                if (isDefined(doctor.ssos)) {
                    doctor.ssos.readonly = false
                }
                if (isDefined(doctor.rppsadeli)) {
                    doctor.rppsadeli.value = ''
                }
                //
                if (isDefined(doctor.practitionerQualification)) {
                    doctor.practitionerQualification.forceHidden = false
                }
                if (isDefined(doctor.practitionerSpecialty)) {
                    doctor.practitionerSpecialty.forceHidden = true
                }
            }
            //
            if (
                isDefined(doctor.ssos) &&
                isDefined(doctor.ssos.value) &&
                Array.isArray(doctor.ssos.value)
            ) {
                let rpps = doctor.ssos.value.filter(
                    (sso) => sso.name === 'rpps'
                )[0]
                if (isDefined(rpps)) {
                    rpps = rpps.id
                    if (isDefined(rpps)) {
                        if (+rpps.charAt() === 8) {
                            doctor.rppsadeli.value = 8
                        } else if (+rpps.charAt() === 0) {
                            doctor.rppsadeli.value = 0
                        }
                        doctor.ssos.readonly = true
                    }
                }
            }
            actions.setDocument(doctor)
        },
        clearASDoctorSearch: (e) => (state, actions) => {
            if (e !== undefined) {
                e.preventDefault()
                e.stopPropagation()
            }
            //
            let searchbar = state.searchbar
            searchbar.value = ''
            searchbar.selected = null
            searchbar.results = null
            actions.updateSearchBar(searchbar)
            //
            actions.resetASDoctor(e)
        },
        resetASDoctor: (e) => (state, actions) => {
            if (e !== undefined) {
                e.preventDefault()
                e.stopPropagation()
            }
            //
            let doctor = state.document
            if (isDefined(doctor.lname)) {
                doctor.lname.value = ''
                doctor.lname.readonly = false
            }
            if (isDefined(doctor.fname)) {
                doctor.fname.value = ''
                doctor.fname.readonly = false
            }
            if (isDefined(doctor.practitionerEmail)) {
                doctor.practitionerEmail.value = ''
                doctor.practitionerEmail.readonly = false
            }
            if (isDefined(doctor.practitionerProfession)) {
                doctor.practitionerProfession.value = ''
                doctor.practitionerProfession.readonly = false
                doctor.practitionerProfession.list =
                    constants.custo.PRACTITIONER_PROFESSIONS
            }
            if (isDefined(doctor.practitionerQualification)) {
                doctor.practitionerQualification.value = ''
                doctor.practitionerQualification.readonly = false
                doctor.practitionerQualification.list =
                    constants.custo.PRACTITIONER_QUALIFICATIONS
                doctor.practitionerQualification.forceHidden = false
            }
            if (isDefined(doctor.practitionerSpecialty)) {
                doctor.practitionerSpecialty.value = ''
                doctor.practitionerSpecialty.readonly = false
                doctor.practitionerSpecialty.list =
                    constants.custo.PRACTITIONER_QUALIFICATIONS
                doctor.practitionerSpecialty.forceHidden = true
            }
            if (isDefined(doctor.practitionerExerciseMode)) {
                doctor.practitionerExerciseMode.value = ''
                doctor.practitionerExerciseMode.list =
                    constants.custo.PRACTITIONER_STATUS
            }

            //
            if (isDefined(doctor.rppsadeli)) {
                doctor.rppsadeli.value = ''
            }
            if (isDefined(doctor.ssos)) {
                doctor.ssos.value = [{ name: 'rpps', id: '' }]
                doctor.ssos.readonly = false
            }
            //
            //
            actions.setDocument(doctor)
        },
        selectSearchResult: (result) => (state, actions) => {
            let searchbar = state.searchbar
            searchbar.selected = result
            actions.updateSearchBar(searchbar)
            //
            actions.resetASDoctor()
            //
            let doctor = state.document
            doctor.lname.value = result["Nom d'exercice"]
            doctor.lname.readonly = true
            doctor.fname.value = result["Prénom d'exercice"]
            doctor.fname.readonly = true
            if (isDefined(result['Adresse e-mail'], true, true)) {
                doctor.practitionerEmail.value = result['Adresse e-mail']
                doctor.practitionerEmail.readonly = true
            }
            if (isDefined(result['Libellé profession'], true, true)) {
                doctor.practitionerProfession.value =
                    result['Libellé profession']
                doctor.practitionerProfession.readonly = true
                doctor.practitionerProfession.list = {
                    0: {
                        key: result['Libellé profession'],
                        value: result['Libellé profession'],
                    },
                }
            }
            if (isDefined(result['Libellé savoir-faire'], true, true)) {
                doctor.practitionerSpecialty.value =
                    result['Libellé savoir-faire']
                doctor.practitionerSpecialty.list = {
                    0: {
                        key: result['Libellé savoir-faire'],
                        value: result['Libellé savoir-faire'],
                    },
                }
            }
            doctor.practitionerSpecialty.readonly = true
            //
            if (isDefined(result["Type d'identifiant PP"], true, true)) {
                doctor.rppsadeli.value = result["Type d'identifiant PP"]
            }
            if (
                isDefined(result["Type d'identifiant PP"], true, true) &&
                isDefined(result['Identifiant PP'], true, true)
            ) {
                doctor.ssos.value = [
                    {
                        name: 'rpps',
                        id: String(
                            result["Type d'identifiant PP"] +
                                '' +
                                result['Identifiant PP']
                        ),
                    },
                ]
                doctor.ssos.readonly = true
            }
            if (isDefined(result['Libellé mode exercice'], true, true)) {
                let parsedResult = Object.values(
                    constants.custo.PRACTITIONER_STATUS
                ).filter(
                    (status) => status.value === result['Libellé mode exercice']
                )[0]
                if (parsedResult) {
                    parsedResult = parsedResult.key || ''
                }
                doctor.practitionerExerciseMode.value = parsedResult || ''
            }
            //
            doctor.practitionerQualification.forceHidden = true
            doctor.practitionerSpecialty.forceHidden = false
            //
            actions.setDocument(doctor)
        },
        updateASDoctorSearch: (field) => (state, actions) => {
            let currentTarget = field.target
            let currentValue = currentTarget.value
            state.searchbar.value = currentValue
        },
        updateDocument: (field) => (state, actions) => {
            let currentdoc = state.document
            let currentTarget = field.target
            if (currentTarget === undefined) {
                currentTarget = field
            }
            if (
                currentTarget &&
                currentTarget !== null &&
                currentTarget !== undefined
            ) {
                let fieldName = currentTarget.name || null
                if (
                    (fieldName === null || fieldName === undefined) &&
                    currentTarget instanceof Element
                ) {
                    fieldName = currentTarget.getAttribute('data-name')
                }
                let fieldValue = currentTarget.value || null
                if (
                    (fieldValue === null || fieldValue === undefined) &&
                    currentTarget instanceof Element
                ) {
                    fieldValue = currentTarget.getAttribute('data-value')
                }
                //
                let currentdocField = currentdoc[fieldName]
                let fieldVerified = isDefined(currentTarget.verified)
                    ? currentTarget.verified
                    : null
                let fieldLang = currentTarget.lang || null
                let fieldType = currentTarget.type || null
                //
                // if (isDefined(currentdocField) && (currentdocField.readonly === true)) {
                //     alert('This field is readonly, editing html to edit this field is nasty! Dont do it!')
                //     currentdocField.value = currentdocField.value
                //     currentdocField.readonly = true;
                //     actions.setDocument(currentdoc);
                //     return false;
                // }
                //
                if (
                    isDefined(fieldValue) &&
                    fieldValue.name === 'clearfield' &&
                    isDefined(currentdoc[fieldName])
                ) {
                    currentdocField = currentdoc[fieldName]
                    currentdocField.value = fieldValue.id
                } else {
                    //
                    let fieldLinkedTo = null
                    if (currentTarget instanceof Element) {
                        fieldLang = currentTarget.getAttribute('data-lang')
                        fieldType = currentTarget.getAttribute('type')
                        fieldLinkedTo =
                            currentTarget.getAttribute('data-linkedTo')
                    }

                    if (fieldType === 'checkbox') {
                        fieldValue = currentTarget.checked
                    }

                    if (
                        fieldValue === -1 ||
                        fieldValue === null ||
                        fieldValue === undefined
                    ) {
                        fieldValue = ''
                        if (
                            isDefined(fieldName) &&
                            (fieldName.indexOf('media') > -1 ||
                                fieldName.indexOf('picture') > -1)
                        ) {
                            fieldValue = false
                        }
                    }
                    let dependsOn = isDefined(currentdocField)
                        ? currentdocField.dependsOn
                        : null
                    if (!isDefined(currentdocField) || isDefined(dependsOn)) {
                        for (var dpdn in currentdoc) {
                            if (currentdoc[dpdn].name === fieldName) {
                                if (!isDefined(dependsOn)) {
                                    dependsOn = currentdoc[dpdn].dependsOn
                                }
                                if (
                                    isDefined(dependsOn) &&
                                    isDefined(currentdoc[dependsOn.field])
                                ) {
                                    let dependsOnValue =
                                        currentdoc[dependsOn.field].value
                                    let dependsFromValue = null,
                                        dependsNoFromValue = null
                                    if (isDefined(currentdoc[dpdn].dependsOn)) {
                                        dependsFromValue =
                                            currentdoc[dpdn].dependsOn.value
                                        dependsNoFromValue =
                                            currentdoc[dpdn].dependsOn.no
                                    }
                                    if (
                                        (Array.isArray(dependsFromValue) &&
                                            dependsFromValue.indexOf(
                                                dependsOnValue
                                            ) > -1) ||
                                        (((Array.isArray(dependsOnValue) &&
                                            dependsOnValue.indexOf(
                                                dependsFromValue
                                            ) > -1) ||
                                            dependsOnValue ===
                                                dependsFromValue) &&
                                            (!isDefined(dependsNoFromValue) ||
                                                (isDefined(
                                                    dependsNoFromValue
                                                ) &&
                                                    ((Array.isArray(
                                                        dependsOnValue
                                                    ) &&
                                                        dependsOnValue.indexOf(
                                                            dependsNoFromValue
                                                        ) === -1) ||
                                                        dependsOnValue !==
                                                            dependsNoFromValue))))
                                    ) {
                                        currentdocField = currentdoc[dpdn]
                                    }
                                }
                            }
                        }
                    }
                    if (isDefined(currentdocField)) {
                        if (fieldType === 'int') {
                            if (!isInteger(fieldValue) && fieldValue !== '') {
                                fieldValue = currentdocField.value
                            }
                        } else if (fieldType === 'alphanum') {
                            if (!isAlphaNum(fieldValue) && fieldValue !== '') {
                                fieldValue = currentdocField.value
                            }
                        } else if (fieldType === 'phone') {
                            const prefix =
                                currentdoc['phonePrefix'].value || null
                            if (
                                !isValidPhoneLength(fieldValue, prefix) &&
                                fieldValue !== ''
                            ) {
                                fieldValue = currentdocField.value
                            }
                        } else if (fieldType === 'float') {
                            if (String(fieldValue).indexOf(',') > -1) {
                                fieldValue = String(fieldValue).replace(
                                    ',',
                                    '.'
                                )
                            }
                            if (!isFloat(fieldValue)) {
                                fieldValue = currentdocField.value
                            }
                        } else if (fieldType === 'array') {
                            let tmpValue = fieldValue
                            if (Array.isArray(tmpValue)) {
                                tmpValue = tmpValue.join(',')
                            }
                            fieldValue = tmpValue.split(',')
                        } else if (fieldType === 'boolean') {
                            if (fieldValue === 'true') {
                                fieldValue = true
                            } else if (fieldValue === '1' || fieldValue === 1) {
                                fieldValue = 1
                            } else if (fieldValue === 'false') {
                                fieldValue = false
                            } else if (fieldValue === '0' || fieldValue === 0) {
                                fieldValue = 0
                            }
                        }

                        if (fieldName === 'timezone' && fieldValue.id) {
                            fieldValue = fieldValue.id
                        }

                        //
                        if (
                            isDefined(fieldValue.expiration) &&
                            isDefined(currentdoc.childexpiration)
                        ) {
                            currentdoc.childexpiration.value =
                                fieldValue.expiration
                        }

                        if (
                            fieldLinkedTo &&
                            fieldLinkedTo !== undefined &&
                            fieldLinkedTo !== null
                        ) {
                            currentdoc[fieldLinkedTo].secondValue = fieldValue
                        }

                        if (
                            fieldLang &&
                            fieldLang !== null &&
                            fieldLang !== undefined &&
                            fieldLang !== ''
                        ) {
                            if (
                                currentdocField.value === '' ||
                                Array.isArray(currentdocField.value)
                            ) {
                                currentdocField.value = {}
                            }
                            if (fieldName === 'formsClinical') {
                                let clinicalInputs = []
                                for (var cInput in fieldValue) {
                                    clinicalInputs.push({
                                        tag: fieldValue[cInput].tag.name,
                                        name: '',
                                        value: null,
                                        min: castToNumberOfFloat(
                                            fieldValue[cInput].min
                                        ),
                                        max: castToNumberOfFloat(
                                            fieldValue[cInput].max
                                        ),
                                        default: castToNumberOfFloat(
                                            fieldValue[cInput].default
                                        ),
                                    })
                                }
                                currentdocField.value[fieldLang] =
                                    clinicalInputs
                            } else {
                                currentdocField.value[fieldLang] = fieldValue
                            }
                            //
                        } else if (currentdocField && currentdocField !== '') {
                            currentdocField.value = fieldValue
                        }
                        //
                        if (
                            currentdocField.duplicateof !== null &&
                            currentdocField.duplicateof !== undefined
                        ) {
                            let duplicateBy =
                                currentdoc[currentdocField.duplicateof]
                            duplicateBy.value = fieldValue
                            for (var duplicate in duplicateBy.duplicateby) {
                                currentdoc[
                                    duplicateBy.duplicateby[duplicate]
                                ].value = fieldValue
                            }
                        }
                    }
                    let base64 = currentTarget.base64 || null
                    if (isDefined(base64)) {
                        // especially for adminpreview
                        if (
                            fieldLang &&
                            fieldLang !== null &&
                            fieldLang !== undefined &&
                            fieldLang !== ''
                        ) {
                            if (
                                !isDefined(currentdocField.base64) ||
                                currentdocField.base64 === '' ||
                                Array.isArray(currentdocField.base64)
                            ) {
                                currentdocField.base64 = {}
                            }
                            currentdocField.base64[fieldLang] = base64
                        } else {
                            currentdocField.base64 = base64
                        }
                    }
                    if (
                        isDefined(currentdocField) &&
                        isDefined(fieldVerified)
                    ) {
                        currentdocField.verified = fieldVerified
                    }
                    //
                    let dynValue = isDefined(currentdocField)
                        ? currentdocField.dynValue
                        : null
                    if (isDefined(dynValue)) {
                        let actsOn = dynValue.actsOn
                        if (isDefined(actsOn)) {
                            for (var dnv in actsOn) {
                                currentdoc[actsOn[dnv]].value = fieldValue
                            }
                        }
                    }
                }
                //
                state.document = currentdoc
                if (fieldName === 'parent') {
                    let originaldocument = state.originaldocument
                    if (
                        isDefined(originaldocument) &&
                        isDefined(originaldocument.parent)
                    ) {
                        originaldocument.parent.value = fieldValue
                    }
                    actions.setOriginalDocument(originaldocument)
                }
                //
                if (fieldValue.synchedCustomer) {
                    let originaldocument = state.originaldocument
                    if (
                        isDefined(originaldocument) &&
                        isDefined(originaldocument.customer)
                    ) {
                        originaldocument.customer = fieldValue.synchedCustomer
                    }
                    actions.setOriginalDocument(originaldocument)
                }
                //
                actions.updateValidation({
                    fieldName: fieldName,
                    fieldValue: fieldValue,
                    fieldLang: fieldLang,
                    returnValue: false,
                })
                //
                const postUpdate = currentdocField.postUpdate
                if (postUpdate) {
                    const mustResetFields = postUpdate.reset
                    if (
                        mustResetFields &&
                        Array.isArray(mustResetFields) &&
                        mustResetFields.length
                    ) {
                        for (var fieldToReset in mustResetFields) {
                            const { key, value } = mustResetFields[fieldToReset]
                            if (key) {
                                currentdoc[key].value = value
                                actions.updateValidation({
                                    fieldName: key,
                                    fieldValue: value,
                                    fieldLang: fieldLang,
                                    returnValue: false,
                                })
                            }
                        }
                    }
                }
            }
        },
        updateDocumentRpps: (field) => (state, actions) => {
            let currentdoc = state.document
            let currentTarget = field.target
            if (currentTarget === undefined) {
                currentTarget = field
            }
            if (
                currentTarget &&
                currentTarget !== null &&
                currentTarget !== undefined
            ) {
                let fieldName = currentTarget.name || null
                if (
                    (fieldName === null || fieldName === undefined) &&
                    currentTarget instanceof Element
                ) {
                    fieldName = currentTarget.getAttribute('data-name')
                }
                let fieldValue = currentTarget.value || null
                if (
                    (fieldValue === null || fieldValue === undefined) &&
                    currentTarget instanceof Element
                ) {
                    fieldValue = currentTarget.getAttribute('data-value')
                }
                let currentdocField = currentdoc[fieldName]
                let substituteName = currentdocField.substitute
                //
                currentdocField.value = [
                    { name: substituteName, id: fieldValue },
                ]
            }
        },
        updateValidations: (toValidDocument) => (state, actions) => {
            let validatedDocument = null
            let fieldName = null
            let fieldValue = null
            let fieldLang = null
            //
            let availableLanguages = toValidDocument.flags
            if (isDefined(availableLanguages)) {
                availableLanguages = availableLanguages.value
            }
            for (var field in toValidDocument) {
                fieldName = field
                if (isDefined(toValidDocument[field])) {
                    fieldValue = toValidDocument[field].value
                    fieldLang = toValidDocument[field].lang
                    //
                    // fieldMin = toValidDocument[field].min;
                    // fieldMax = toValidDocument[field].max;
                    // fieldDefault = toValidDocument[field].default;
                    let found = false
                    if (isDefined(availableLanguages)) {
                        for (var lng in availableLanguages) {
                            let lang = availableLanguages[lng].key
                            if (
                                isDefined(fieldValue) &&
                                isDefined(fieldValue[lang])
                            ) {
                                found = true
                                if (Array.isArray(fieldValue[lang])) {
                                    // if (isDefined(fieldValue[lang][0].min)) {
                                    //     fieldMin = fieldValue[lang][0].min;
                                    // }
                                    // if (isDefined(fieldValue[lang][0].max)) {
                                    //     fieldMax = fieldValue[lang][0].max;
                                    // }
                                    // if (isDefined(fieldValue[lang][0].default)) {
                                    //     fieldDefault = fieldValue[lang][0].default;
                                    // }
                                    fieldValue = fieldValue[lang].value
                                    fieldLang = lang
                                } else {
                                    fieldValue = fieldValue[lang]
                                    fieldLang = lang
                                }
                                validatedDocument = actions.updateValidation({
                                    fieldName: fieldName,
                                    fieldValue: fieldValue,
                                    fieldLang: fieldLang,
                                    returnValue: true,
                                })
                            }
                        }
                    }
                    if (!found) {
                        validatedDocument = actions.updateValidation({
                            fieldName: fieldName,
                            fieldValue: fieldValue,
                            fieldLang: fieldLang,
                            returnValue: true,
                        })
                    }
                }
            }
            return validatedDocument
        },
        updateValidation:
            ({ fieldName, fieldValue, fieldLang, returnValue = false }) =>
            (state, actions) => {
                let currentdoc = state.document
                if (!isDefined(fieldName)) {
                    return false
                }
                let validation =
                    currentdoc[fieldName] !== undefined
                        ? currentdoc[fieldName].validation
                        : null
                let isValid = false,
                    val = '',
                    entry = '',
                    errorLang = null
                if (isDefined(validation)) {
                    let validationMode = validation.type
                    let validationModeNoValidationType =
                        validation.noValidationType
                    let validationMandatory = validation.mandatory
                    if (
                        isDefined(validation.notmandatory) &&
                        validation.notmandatory.indexOf(
                            state.overridecustomer
                        ) > -1
                    ) {
                        validationMandatory = false
                        isValid = true
                    } else {
                        //
                        if (
                            (fieldValue !== '' &&
                                isDefined(fieldValue) &&
                                fieldValue.length > 0) ||
                            validationMandatory === true
                        ) {
                            // if (validationMandatory === true) { !!!!!!!!!!!!! TO SURVEY !!!!!!!!!!!!!
                            if (validationMode === 'isChecked') {
                                if (fieldValue === true) {
                                    isValid = true
                                }
                            } else if (validationMode === 'atLeastOneChecked') {
                                if (
                                    currentdoc[fieldName].type === 'translation'
                                ) {
                                    let found = false
                                    for (val in currentdoc[fieldName].value) {
                                        for (entry in currentdoc[fieldName]
                                            .value[val]) {
                                            if (
                                                (currentdoc[fieldName].value[
                                                    val
                                                ][entry].goodResponse !==
                                                    undefined &&
                                                    currentdoc[fieldName].value[
                                                        val
                                                    ][entry].goodResponse ===
                                                        1) ||
                                                (currentdoc[fieldName].value[
                                                    val
                                                ][entry].goodResponse ===
                                                    undefined &&
                                                    currentdoc[fieldName].value[
                                                        val
                                                    ][entry].value !== '')
                                            ) {
                                                found = true
                                                break
                                            }
                                        }
                                        if (found) {
                                            isValid = true
                                            break
                                        }
                                    }
                                } else {
                                    if (
                                        Array.isArray(
                                            currentdoc[fieldName].value
                                        ) &&
                                        currentdoc[fieldName].value.length > 0
                                    ) {
                                        isValid = true
                                    } else {
                                        for (val in currentdoc[fieldName]
                                            .value) {
                                            if (
                                                currentdoc[fieldName].value[
                                                    val
                                                ] === true
                                            ) {
                                                isValid = true
                                                break
                                            }
                                        }
                                    }
                                }
                            } else if (
                                validationMode === 'not-empty' ||
                                validationMode === 'selected'
                            ) {
                                let found = true
                                if (
                                    currentdoc[fieldName].type ===
                                        'translation' &&
                                    isDefined(currentdoc.langs)
                                ) {
                                    for (var lang in currentdoc.langs.value) {
                                        if (
                                            currentdoc.langs.value[lang] ===
                                                true &&
                                            currentdoc[fieldName].value[
                                                lang
                                            ] === ''
                                        ) {
                                            found = false
                                            if (
                                                lang !== null &&
                                                lang !== undefined
                                            ) {
                                                errorLang = lang
                                            }
                                            break
                                        }
                                    }
                                    if (found) {
                                        isValid = true
                                    }
                                } else {
                                    if (
                                        fieldValue !== '' &&
                                        isDefined(fieldValue)
                                    ) {
                                        isValid = true
                                    }
                                }
                            } else if (validationMode === 'no-empty-entry') {
                                isValid = true
                                for (entry in currentdoc[fieldName].value) {
                                    for (var key in currentdoc[fieldName].value[
                                        entry
                                    ]) {
                                        if (
                                            currentdoc[fieldName].value[entry][
                                                key
                                            ] === ''
                                        ) {
                                            isValid = false
                                        }
                                    }
                                }
                            } else if (
                                validationMode === 'atLeastTwoFilled' ||
                                validationMode ===
                                    'atLeastTwoFilledForceValueToOrder'
                            ) {
                                let nInput = 0
                                let orderInput = 0
                                let inputValid = true
                                let validationNeeded = true
                                let values = currentdoc[fieldName].value
                                if (isDefined(validationModeNoValidationType)) {
                                    let noValidationModeField =
                                        validationModeNoValidationType.field
                                    let noValidationModeValue =
                                        validationModeNoValidationType.value
                                    if (
                                        currentdoc[noValidationModeField]
                                            .value === noValidationModeValue
                                    ) {
                                        isValid = true
                                        validationNeeded = false
                                    }
                                }
                                if (validationNeeded) {
                                    let emptyValueOrName = false
                                    isValid = true
                                    //
                                    let activatedLanguage = currentdoc.langs
                                    if (isDefined(activatedLanguage)) {
                                        activatedLanguage =
                                            activatedLanguage.value
                                    }
                                    //
                                    for (var evalLang in values) {
                                        if (
                                            isDefined(activatedLanguage) &&
                                            activatedLanguage[evalLang] ===
                                                false
                                        ) {
                                            // no validation for deactivated language
                                            continue
                                        }
                                        for (var evalBtnIdx in values[
                                            evalLang
                                        ]) {
                                            if (values[evalLang].length < 2) {
                                                isValid = false
                                                inputValid = false
                                                break
                                            }
                                            inputValid = true
                                            orderInput = 0
                                            for (var evalBtnField in values[
                                                evalBtnIdx
                                            ]) {
                                                if (
                                                    evalBtnField !== 'tag' &&
                                                    evalBtnField !== '' &&
                                                    (values[evalBtnIdx][
                                                        evalBtnField
                                                    ] === '' ||
                                                        values[evalBtnIdx][
                                                            evalBtnField
                                                        ] === null)
                                                ) {
                                                    if (
                                                        validationMode ===
                                                            'atLeastTwoFilledForceValueToOrder' &&
                                                        evalBtnField ===
                                                            'value' &&
                                                        +values[evalBtnIdx][
                                                            evalBtnField
                                                        ]['order'] > -1
                                                    ) {
                                                        values[evalBtnIdx][
                                                            evalBtnField
                                                        ] =
                                                            values[evalBtnIdx][
                                                                'order'
                                                            ].toString()
                                                    } else {
                                                        if (
                                                            evalBtnField ===
                                                            'order'
                                                        ) {
                                                            orderInput++
                                                            values[evalBtnIdx][
                                                                evalBtnField
                                                            ] = orderInput
                                                        }
                                                    }
                                                }
                                                if (
                                                    [
                                                        'name',
                                                        'value',
                                                        'tag',
                                                    ].indexOf(evalBtnField) > -1
                                                ) {
                                                    if (
                                                        values[evalBtnIdx][
                                                            evalBtnField
                                                        ] === ''
                                                    ) {
                                                        inputValid = false
                                                        isValid = false
                                                        break
                                                    }
                                                }
                                            }
                                            if (!inputValid) {
                                                break
                                            }
                                        }
                                        if (!inputValid) {
                                            break
                                        }
                                    }
                                }
                            } else if (
                                validationMode === 'atLeastOneSelected'
                            ) {
                                let values = currentdoc[fieldName].value
                                if (Array.isArray(values)) {
                                    if (values.length > 0) {
                                        isValid = true
                                    }
                                } else {
                                    if (Object.keys(values).length > 0) {
                                        isValid = true
                                    }
                                }
                            } else if (validationMode === 'atLeastOneFilled') {
                                let found = false
                                for (val in currentdoc[fieldName].value) {
                                    if (
                                        currentdoc[fieldName].type ===
                                        'translation'
                                    ) {
                                        for (entry in currentdoc[fieldName]
                                            .value[val]) {
                                            if (
                                                currentdoc[fieldName].value[
                                                    val
                                                ][entry] !== ''
                                            ) {
                                                found = true
                                                break
                                            }
                                        }
                                    } else {
                                        if (
                                            currentdoc[fieldName].value[val] !==
                                            ''
                                        ) {
                                            found = true
                                            break
                                        }
                                    }
                                    if (found) {
                                        break
                                    }
                                }
                                if (found) {
                                    isValid = true
                                }
                            } else if (validationMode === 'is-email') {
                                let value = currentdoc[fieldName].value || null
                                if (isDefined(value) && value !== '') {
                                    // eslint-disable-next-line
                                    isValid = isValidEmail(value) ? true : false
                                }
                            } else if (validationMode === 'is-phone') {
                                const value =
                                    currentdoc[fieldName].value || null
                                if (value) {
                                    const prefix =
                                        currentdoc['phonePrefix'].value || null
                                    isValid = isValidPhone(value, prefix)
                                }
                            } else if (validationMode === 'is-date') {
                                let value = currentdoc[fieldName].value || null
                                if (isDefined(value) && value !== '') {
                                    var testDate = null
                                    if (value.date !== undefined) {
                                        if (value.date.indexOf(' ') > -1) {
                                            testDate = new Date(
                                                value.date.replace(' ', 'T') +
                                                    'Z'
                                            )
                                        } else {
                                            testDate = new Date(value.date)
                                        }
                                    } else if (value.day !== undefined) {
                                        testDate = new Date(
                                            value.year +
                                                '/' +
                                                value.month +
                                                '/' +
                                                value.day
                                        )
                                    } else {
                                        if (value.indexOf(' ') > -1) {
                                            testDate = new Date(
                                                value.replace(' ', 'T') + 'Z'
                                            )
                                        } else {
                                            testDate = new Date(value)
                                        }
                                    }
                                    if (
                                        testDate instanceof Date &&
                                        !isNaN(testDate)
                                    ) {
                                        isValid = true
                                    }
                                }
                            } else if (validationMode.indexOf('length') > -1) {
                                let value = currentdoc[fieldName].value || null
                                if (isDefined(value) && value !== '') {
                                    if (Array.isArray(value)) {
                                        if (isDefined(value[0].value)) {
                                            value = value[0].value
                                        } else if (isDefined(value[0].id)) {
                                            value = value[0].id
                                        }
                                    }
                                    let refLength = validationMode.split('/')[1]
                                    if (
                                        validationMode.indexOf('exact-length') >
                                        -1
                                    ) {
                                        if (
                                            String(value).length === +refLength
                                        ) {
                                            isValid = true
                                        }
                                    } else if (
                                        validationMode.indexOf('max-length') >
                                        -1
                                    ) {
                                        if (
                                            String(value).length <= +refLength
                                        ) {
                                            isValid = true
                                        }
                                    } else if (
                                        validationMode.indexOf('min-length') >
                                        -1
                                    ) {
                                        if (
                                            String(value).length >= +refLength
                                        ) {
                                            isValid = true
                                        }
                                    }
                                }
                            } else if (
                                validationMode === 'minMaxDefault' ||
                                validationMode === 'minMaxDefaultStep'
                            ) {
                                let values = currentdoc[fieldName].value || null
                                if (isDefined(fieldLang)) {
                                    values = values[fieldLang]
                                }
                                isValid = true
                                if (isDefined(values) && values.length > 0) {
                                    for (var mVal in values) {
                                        let min = castToNumberOfFloat(
                                            values[mVal].min
                                        )
                                        let max = castToNumberOfFloat(
                                            values[mVal].max
                                        )
                                        let defaut = castToNumberOfFloat(
                                            values[mVal].default
                                        )
                                        //
                                        if (
                                            !isDefined(min) ||
                                            min === '' ||
                                            !isDefined(max) ||
                                            max === '' ||
                                            !isDefined(defaut) ||
                                            defaut === ''
                                        ) {
                                            isValid = false
                                        } else {
                                            if (min > max || min === max) {
                                                isValid = false
                                            } else if (
                                                min > defaut ||
                                                max < defaut
                                            ) {
                                                isValid = false
                                            }
                                        }
                                        //
                                        if (
                                            validationMode ===
                                            'minMaxDefaultStep'
                                        ) {
                                            let step = castToNumberOfFloat(
                                                values[mVal].step
                                            )
                                            if (
                                                !isDefined(step) ||
                                                step === ''
                                            ) {
                                                isValid = false
                                            }
                                        }
                                    }
                                } else {
                                    isValid = false
                                }
                                //
                            }
                            if (isDefined(currentdoc[fieldName].verified)) {
                                if (!currentdoc[fieldName].verified) {
                                    isValid = false
                                }
                            }
                        } else {
                            //
                            isValid = true
                        }
                    }
                    if (currentdoc[fieldName].verified) {
                        isValid = true
                    }
                    let dependsOn = currentdoc[fieldName].dependsOn
                    if (isDefined(dependsOn)) {
                        let dependsOnField = dependsOn.field
                        let dependsOnValue = dependsOn.value
                        let dependsFromField = currentdoc[dependsOnField]
                        // TO SURVEY
                        if (isDefined(dependsFromField)) {
                            if (
                                String(dependsFromField.value) !==
                                    String(dependsOnValue) &&
                                (!Array.isArray(dependsFromField.value) ||
                                    (Array.isArray(dependsFromField.value) &&
                                        dependsFromField.value.indexOf(
                                            dependsOnValue
                                        ) === -1)) &&
                                (!Array.isArray(dependsOnValue) ||
                                    (Array.isArray(dependsOnValue) &&
                                        dependsOnValue.indexOf(
                                            dependsFromField.value
                                        ) === -1))
                            ) {
                                isValid = true
                            }
                        }
                        // TO SURVEY
                    }
                    //
                    currentdoc[fieldName].validation.isValid = isValid
                    currentdoc[fieldName].validation.errorLang = errorLang
                    if (!isValid) {
                        if (fieldLang !== undefined && fieldLang !== null) {
                            errorLang = fieldLang
                        }
                    } else {
                        errorLang = null
                    }
                    if (!returnValue && !state.previewDisplayed) {
                        state.document = currentdoc
                    }
                }
                if (!returnValue && !state.previewDisplayed) {
                    actions.setDocument(currentdoc)
                } else {
                    return currentdoc
                }
            },
        sendFormAndRedirect: (opt) => (state, actions) => {
            if (opt) {
                actions.setRedirectAfter(opt)
                actions.sendForm()
            }
        },
        setRedirectAfter: (opt) => (state, actions) => {
            if (opt.from === 'program' || opt.from === 'program-session') {
                if (opt.to === 'newsession') {
                    actions.setRedirectAfterFormSended(
                        addCSRFToken(
                            allRoutes['private.programs.sessions'].pathname
                        )
                    )
                } else if (opt.to === 'session') {
                    actions.setRedirectAfterFormSended(
                        addCSRFToken(
                            allRoutes['private.programs.sessions.edit'].pathname
                        )
                    )
                    if (opt.refId !== undefined) {
                        actions.setRefId(opt.refId.value || opt.refId)
                    }
                    if (opt.refSId !== undefined) {
                        actions.setRefSId(opt.refSId.value || opt.refSId)
                    }
                } else if (opt.to === 'newstep') {
                    actions.setRedirectAfterFormSended(
                        addCSRFToken(
                            allRoutes['private.programs.sessions.steps']
                                .pathname
                        )
                    )
                } else if (opt.to === 'step') {
                    actions.setRedirectAfterFormSended(
                        addCSRFToken(
                            allRoutes['private.programs.sessions.steps.edit']
                                .pathname
                        )
                    )
                    if (opt.refSSId !== undefined) {
                        actions.setRefSSId(opt.refSSId.value || opt.refSSId)
                    }
                }
                if (opt.refType) {
                    actions.setRefType(opt.refType)
                }
            } else if (opt.from === 'customer') {
                if (opt.to === 'scenario') {
                    actions.setRedirectAfterFormSended(
                        addCSRFToken(
                            allRoutes['private.users.customers.scenario.create']
                                .pathname
                        )
                    )
                }
            }
        },
        deleteEntryAndUpdate: (opt) => (state, actions) => {
            //
        },
        setRedirectAfterFormSended: (newState) => (state) => ({
            redirectAfterFormSended: newState,
        }),
        sendForm: (e) => (state, actions) => {
            if (e !== undefined) {
                e.preventDefault()
                e.stopPropagation()
            }
            if (state.document.type && state.document.type.value === '') {
                state.document.type.value = state.editType
            }
            if (state.document.status && state.document.status.value === '') {
                state.document.status.value = 0
            }
            actions.setSubmitting(true)

            let form = actions.updateValidations(state.document)
            let fieldValidation = null,
                fieldValue = ''
            for (var field in form) {
                fieldValidation = form[field].validation
                fieldValue = form[field].value
                if (fieldValidation === undefined || fieldValidation === null) {
                    // no validation
                } else {
                    let validationMandatory = fieldValidation.mandatory
                    if (
                        isDefined(fieldValidation.notmandatory) &&
                        fieldValidation.notmandatory.indexOf(
                            state.overridecustomer
                        ) > -1
                    ) {
                        validationMandatory = false
                    }
                    if (
                        (fieldValue !== '' &&
                            fieldValue !== null &&
                            fieldValue !== undefined) ||
                        validationMandatory === true
                    ) {
                        let dependsOnOtherField = form[field].dependsOn
                        if (dependsOnOtherField !== undefined) {
                            let otherField = dependsOnOtherField.field
                            let otherValue = dependsOnOtherField.value
                            let ignore = true
                            if (isDefined(form[otherField])) {
                                if (
                                    String(form[otherField].value) ===
                                    String(otherValue)
                                ) {
                                    ignore = false
                                } else if (
                                    (Array.isArray(form[otherField].value) &&
                                        form[otherField].value.indexOf(
                                            otherValue
                                        ) > -1) ||
                                    (Array.isArray(otherValue) &&
                                        otherValue.indexOf(
                                            form[otherField].value
                                        ) > -1)
                                ) {
                                    ignore = false
                                }
                            }
                            if (ignore) {
                                continue
                            }
                        }
                        //
                        if (!fieldValidation.isValid) {
                            if (
                                isDefined(form[field].verified) &&
                                form[field].verified === false
                            ) {
                                actions.setQueryMessage({
                                    newStateKind: 'error',
                                    newStateContent:
                                        t(fieldValidation.verifiedMessage) +
                                        (fieldValidation.errorLang !== null
                                            ? ' (' +
                                              fieldValidation.errorLang +
                                              ')'
                                            : ''),
                                })
                            } else {
                                actions.setQueryMessage({
                                    newStateKind: 'error',
                                    newStateContent:
                                        t(fieldValidation.message) +
                                        (fieldValidation.errorLang !== null
                                            ? ' (' +
                                              fieldValidation.errorLang +
                                              ')'
                                            : ''),
                                })
                            }
                            actions.displaySuccessMessage(true)
                            actions.setSubmitting(false)

                            // when form is submitted first time, validation is activated and
                            //  input will go from [red] error to [transparent] valid background dynamically
                            actions.setFormSubmitted(true)
                            return false
                        }
                    }
                }
            }

            actions.setQueryMessage({
                newStateKind: 'success',
                newStateContent: '',
            })
            let parsedForm = deepCopy(form) // eval are send
            parsedForm = actions.parseDocumentToBeSended(parsedForm)
            let forms = []
            for (var fld in parsedForm) {
                if (state.document[fld] !== undefined) {
                    if (state.document[fld].sendElsewhere !== undefined) {
                        let trgFldName = fld
                        if (state.document[fld].substitute !== undefined) {
                            trgFldName = state.document[fld].substitute
                            if (state.document[fld].wrappedIn !== undefined) {
                                trgFldName = state.document[fld].wrappedIn
                            }
                            if (isDefined(trgFldName)) {
                                if (!Array.isArray(trgFldName)) {
                                    trgFldName = [trgFldName]
                                }
                                // let trgFld = parsedForm[trgFldName];
                                // if (trgFld && (trgFld !== undefined)) {
                                //     trgFld.sendElsewhere = state.document[fld].sendElsewhere;
                                //     forms.push(trgFld);
                                //     delete parsedForm[trgFldName];
                                // }
                                // TO SURVEY
                                for (var tFld in trgFldName) {
                                    let trgFld = parsedForm[trgFldName[tFld]]
                                    if (isDefined(trgFld)) {
                                        trgFld.sendElsewhere =
                                            state.document[fld].sendElsewhere
                                        forms.push(trgFld)
                                        delete parsedForm[trgFldName[tFld]]
                                    }
                                }
                                // TO SURVEY
                            }
                        }
                    } else if (state.document[fld].duplicateby !== undefined) {
                        let duplicateBy = state.document[fld]
                        for (var duplicate in duplicateBy.duplicateby) {
                            delete parsedForm[
                                duplicateBy.duplicateby[duplicate]
                            ]
                        }
                    }
                }
            }
            // return false;
            actions.setSecondForms(forms)
            actions.sendMedias(parsedForm)
        },
        parseDocumentToBeSended: (origForm) => (state, actions) => {
            let form = {}
            let fName = '',
                fValue = '',
                fType = '',
                fField = '',
                fSending = true,
                fForceHidden = false
            let isWrapped = false
            // flags & tabs must not be send. Id is just to differ PUT/POST, original is a ref to original obj,
            //  template optionnal and preview is for admin preview
            let toBeIgnored = [
                'flags',
                'id',
                'tabs',
                'template',
                'original',
                'preview',
            ]
            //
            for (var field in origForm) {
                isWrapped = false
                if (
                    origForm[field] !== undefined &&
                    origForm[field].wrappedIn !== undefined
                ) {
                    isWrapped = origForm[field].wrappedIn
                }
                if (toBeIgnored.indexOf(field) === -1) {
                    fName =
                        origForm[field] !== undefined &&
                        origForm[field].substitute !== undefined
                            ? origForm[field].substitute
                            : field
                    //
                    let dependsOnOtherField = origForm[field].dependsOn
                    if (isDefined(dependsOnOtherField)) {
                        let ignore = true
                        if (
                            isDefined(state.document[dependsOnOtherField.field])
                        ) {
                            if (
                                String(dependsOnOtherField.value) ===
                                String(
                                    state.document[dependsOnOtherField.field]
                                        .value
                                )
                            ) {
                                ignore = false
                            } else if (
                                (Array.isArray(
                                    state.document[dependsOnOtherField.field]
                                        .value
                                ) &&
                                    state.document[
                                        dependsOnOtherField.field
                                    ].value.indexOf(dependsOnOtherField.value) >
                                        -1) ||
                                (Array.isArray(dependsOnOtherField.value) &&
                                    dependsOnOtherField.value.indexOf(
                                        state.document[
                                            dependsOnOtherField.field
                                        ].value
                                    ) > -1)
                            ) {
                                ignore = false
                            }
                        }
                        if (ignore) {
                            if (dependsOnOtherField.ifUnset !== undefined) {
                                origForm[field].value =
                                    dependsOnOtherField.ifUnset
                            } else {
                                continue
                            }
                        }
                    }
                    //
                    if (origForm[field] !== undefined) {
                        fValue = origForm[field].value
                        fType = origForm[field].type
                        fField = origForm[field].field
                        fSending = isDefined(origForm[field].sending)
                            ? origForm[field].sending
                            : true
                        fForceHidden = origForm[field].forceHidden
                        if (
                            !fSending ||
                            fForceHidden ||
                            (fSending == 'not-if-false' &&
                                (fValue === 'false' || fValue === '0'))
                        ) {
                            continue
                        }
                        let beforeSend = origForm[field].beforeSend
                        if (isDefined(beforeSend)) {
                            if (
                                beforeSend.ifempty !== undefined &&
                                (!isDefined(fValue) ||
                                    fValue.length === 0 ||
                                    fValue === '')
                            ) {
                                if (beforeSend.ifempty === 'remove') {
                                    fValue = undefined
                                } else {
                                    fValue = beforeSend.ifempty
                                }
                            }
                            if (
                                beforeSend.ifarray !== undefined &&
                                Array.isArray(fValue) &&
                                isDefined(fValue[0])
                            ) {
                                // for [ssos] field only
                                if (
                                    fValue[0].name ===
                                    origForm[field].substitute
                                ) {
                                    fValue = fValue[0][beforeSend.ifarray]
                                } else {
                                    fValue = null
                                }
                            }
                            if (
                                beforeSend.ifvalue !== undefined &&
                                fValue === beforeSend.ifvalue.key
                            ) {
                                fValue = beforeSend.ifvalue.value
                            }
                        }
                        if (fValue !== undefined && fValue !== null) {
                            if (
                                fValue.length > 0 &&
                                fType !== undefined &&
                                fType.indexOf('autocomplete') > -1
                            ) {
                                // for multiple linked entries, like linked rewards, etc ...
                                let nfValue = []
                                for (var entry in fValue) {
                                    if (fValue[entry].id !== undefined) {
                                        nfValue.push(fValue[entry].id)
                                    }
                                }
                                if (nfValue.length === 0) {
                                    nfValue = fValue
                                }
                                fValue = nfValue
                            } else if (
                                fValue.id !== undefined &&
                                (fName.indexOf('Id') > 0 ||
                                    ['customer', 'parent'].indexOf(fName) > -1)
                            ) {
                                // for single linked entry, like linked program, linked offer, etc ...
                                fValue = fValue.id
                            } else if (
                                fValue.name !== undefined &&
                                (fName.indexOf('Name') > 0 ||
                                    ['city'].indexOf(fName) > -1)
                            ) {
                                // for single linked entry, like city, etc ...
                                fValue = fValue.name
                            }
                            if (Array.isArray(fValue) && fValue.length > 0) {
                                // for linked rewards and other linked that embed key value
                                for (
                                    var i = 0, len = fValue.length;
                                    i < len;
                                    i++
                                ) {
                                    if (
                                        isDefined(fValue[i]) &&
                                        fValue[i].key !== undefined &&
                                        fValue[i].key !== null
                                    ) {
                                        fValue[i] = fValue[i].key
                                    }
                                }
                            }
                        }
                        if (fType === 'translation') {
                            for (var lang in fValue) {
                                var lfValue = fValue[lang]
                                for (var val in lfValue) {
                                    if (lfValue[val] !== null) {
                                        if (
                                            lfValue[val].tag !== null &&
                                            lfValue[val].tag !== undefined &&
                                            lfValue[val].tag.name !== undefined
                                        ) {
                                            lfValue[val]['tag'] =
                                                lfValue[val].tag.name
                                        } else {
                                            if (fName === 'steps') {
                                                if (
                                                    lfValue[val].value !==
                                                    undefined
                                                ) {
                                                    lfValue[val] =
                                                        lfValue[val].value
                                                } else if (
                                                    lfValue[val].description !==
                                                    undefined
                                                ) {
                                                    lfValue[val] =
                                                        lfValue[val].description
                                                }
                                            }
                                        }
                                    }
                                }
                                fValue[lang] = lfValue
                            }

                            // to match API expectations
                            let parsedLFValue = [],
                                stepLang = '',
                                stepValue = ''
                            if (fName === 'steps') {
                                for (stepLang in fValue) {
                                    for (stepValue in fValue[stepLang]) {
                                        if (
                                            parsedLFValue[stepValue] ===
                                            undefined
                                        ) {
                                            parsedLFValue[stepValue] = {}
                                        }
                                        parsedLFValue[stepValue][stepLang] =
                                            fValue[stepLang][stepValue]
                                    }
                                }
                                fValue = parsedLFValue
                            } else if (fName === 'extras') {
                                for (stepLang in fValue) {
                                    for (stepValue in fValue[stepLang]) {
                                        if (
                                            parsedLFValue[stepValue] ===
                                            undefined
                                        ) {
                                            parsedLFValue[stepValue] = {}
                                        }
                                        if (
                                            parsedLFValue[stepValue][
                                                'content'
                                            ] === undefined
                                        ) {
                                            parsedLFValue[stepValue][
                                                'content'
                                            ] = {}
                                        }
                                        if (
                                            parsedLFValue[stepValue][
                                                'title'
                                            ] === undefined
                                        ) {
                                            parsedLFValue[stepValue]['title'] =
                                                {}
                                        }
                                        parsedLFValue[stepValue]['content'][
                                            stepLang
                                        ] =
                                            fValue[stepLang][stepValue].value
                                                .content ||
                                            fValue[stepLang][stepValue].value
                                                .description
                                        parsedLFValue[stepValue]['title'][
                                            stepLang
                                        ] =
                                            fValue[stepLang][
                                                stepValue
                                            ].value.title
                                    }
                                }
                                fValue = parsedLFValue
                            }
                        }

                        if (fValue !== undefined && fValue !== '') {
                            if (
                                [
                                    'owner',
                                    'sliderTag',
                                    'textareaTag',
                                    'counterTag',
                                    'statcounterTag',
                                    'medicalStudyEvalMeasureKpi',
                                ].indexOf(fName) > -1 &&
                                isDefined(fValue) &&
                                fValue.id !== undefined
                            ) {
                                fValue = fValue.id
                            } else if (['tags'].indexOf(fName) > -1) {
                                fValue = fValue.map((m) => m.alias || m)
                            } else if (['customers'].indexOf(fName) > -1) {
                                fValue = fValue.filter((f) => f !== '-1')
                            } else if (['answers'].indexOf(fName) > -1) {
                                for (
                                    var j = 0, lenj = fValue.length;
                                    j < lenj;
                                    j++
                                ) {
                                    if (fValue[j].id !== null) {
                                        form['answerContent_' + fValue[j].id] =
                                            fValue[j].content
                                    } else {
                                        form['answerContent'] =
                                            fValue[j].content
                                    }
                                }
                                delete form[fName]
                                fName = null
                            }
                        }

                        if (fType === 'array' && !Array.isArray(fValue)) {
                            fValue = [fValue]
                        } else if (fType === 'boolean') {
                            // if ((fValue === 'true') || (fValue === '1') || (fValue === 1)) {
                            //     fValue = true;
                            // } else if ((fValue === 'false') || (fValue === '0') || (fValue === 0)) {
                            //     fValue = false;
                            // }
                            if (fValue === 'true') {
                                fValue = true
                            } else if (fValue === 'false') {
                                fValue = false
                            }
                        } else if (fType === 'date') {
                            let format = origForm[field].format
                            if (format === 'Y-m-d' && fValue.date) {
                                fValue = fValue.date.split(' ')[0]
                            }
                        } else if (fType === 'phone' || fName === 'phone') {
                            if (Array.isArray(fValue)) {
                                fValue = fValue.join('')
                            }
                        } else if (
                            fType === 'int' &&
                            Number.isInteger(+fValue) &&
                            !Number.isNaN(+fValue)
                        ) {
                            if (
                                typeof fValue === 'string' &&
                                fName !== 'phone'
                            ) {
                                fValue = fValue.padStart(fValue.length, '0')
                            }
                        } else if (fType === 'float' && isFloat(fValue)) {
                            fValue = parseFloat(fValue)
                        }
                        if (fValue === 'null') {
                            fValue = null
                        }

                        if (fName === 'locale') {
                            if (state.originaldocument.customer) {
                                const customerRegion =
                                    state.originaldocument.customer.country
                                if (customerRegion) {
                                    fValue =
                                        fValue.toLowerCase() +
                                        '-' +
                                        customerRegion.toUpperCase()
                                }
                            }
                        }

                        // MISSING DURATION

                        if (fName !== null) {
                            if (fName.indexOf('.') > -1) {
                                // for subkey object like user security, user settings, etc ...
                                let array = fName.split('.')
                                if (form[array[0]] === undefined) {
                                    form[array[0]] = {}
                                }
                                form[array[0]][array[1]] = fValue
                            } else {
                                if (isWrapped !== false) {
                                    if (!Array.isArray(isWrapped)) {
                                        isWrapped = [isWrapped]
                                    }
                                    for (var wrapped in isWrapped) {
                                        if (
                                            form[isWrapped[wrapped]] ===
                                            undefined
                                        ) {
                                            form[isWrapped[wrapped]] = {}
                                        }
                                        form[isWrapped[wrapped]][fName] = fValue
                                    }
                                } else {
                                    if (fField !== 'readonly') {
                                        form[fName] = fValue
                                    } else if (
                                        origForm[field].sending === true
                                    ) {
                                        form[fName] = fValue
                                    }
                                }
                            }
                        }
                        //
                        if (origForm[field].secondName !== undefined) {
                            form[origForm[field].secondName] =
                                origForm[field].secondValue
                        }
                    }
                }
            }
            if (form.langs && form.langs !== undefined) {
                for (var l in form.langs) {
                    let found = false
                    for (var rl in constants.custo.LANGUAGES) {
                        if (constants.custo.LANGUAGES[rl].key === l) {
                            found = true
                        }
                    }
                    if (!found) {
                        delete form.langs[l]
                    }
                }
            }
            return form
        },
        sendMedias: (form) => (state, actions) => {
            state.nMedia = 0
            let mediaFound = false
            var lang = '',
                transMedia = '',
                transField = ''
            if (form.formDatas !== undefined) {
                // second form sending, the one used for evaluation
                //  will be sended right after the legacy first one, using its id as reference
                for (lang in form.formDatas) {
                    for (transField in form.formDatas[lang]) {
                        for (transMedia in form.formDatas[lang][transField]) {
                            if (transMedia.indexOf('media') > -1) {
                                state.nMedia++
                            }
                        }
                    }
                }
                //
                if (state.nMedia > 0) {
                    mediaFound = true
                }
                //
                for (lang in form.formDatas) {
                    for (transField in form.formDatas[lang]) {
                        for (transMedia in form.formDatas[lang][transField]) {
                            if (transMedia.indexOf('media') > -1) {
                                let file =
                                    form.formDatas[lang][transField][transMedia]
                                actions.sendMedia({
                                    form,
                                    file: file,
                                    field: transField,
                                    lang: lang,
                                    media: transMedia,
                                })
                            }
                        }
                    }
                }
            } else {
                // first, legacy form sending
                //  searching for media (5 differents sending method)
                //  -> then sending fields
                for (var field in form) {
                    if (field.indexOf('mediaTranslations') > -1) {
                        for (lang in form[field]) {
                            state.nMedia++
                        }
                    } else if (field.indexOf('picturesTranslations') > -1) {
                        for (lang in form[field]) {
                            for (transMedia in form[field][lang]) {
                                state.nMedia++
                            }
                        }
                    } else if (field.indexOf('pictures') > -1) {
                        for (var pict in form[field]) {
                            state.nMedia++
                        }
                    } else if (field.indexOf('media') > -1) {
                        state.nMedia++
                    }
                }
                //
                if (state.nMedia > 0) {
                    mediaFound = true
                }
                //
                for (field in form) {
                    if (field.indexOf('mediaTranslations') > -1) {
                        for (lang in form[field]) {
                            let file = form[field][lang]
                            actions.sendMedia({
                                form,
                                file: file,
                                field: field,
                                lang: lang,
                            })
                        }
                    } else if (field.indexOf('picturesTranslations') > -1) {
                        for (lang in form[field]) {
                            for (transMedia in form[field][lang]) {
                                let file = form[field][lang][transMedia]
                                actions.sendMedia({
                                    form,
                                    file: file,
                                    field: field,
                                    lang: lang,
                                    media: transMedia,
                                    isTransPicture: true,
                                })
                            }
                        }
                    } else if (field.indexOf('pictures') > -1) {
                        for (pict in form[field]) {
                            let file = form[field][pict]
                            actions.sendMedia({
                                form,
                                file: file,
                                field: field,
                                media: pict,
                            })
                        }
                    } else if (field.indexOf('media') > -1) {
                        let file = form[field]
                        actions.sendMedia({ form, file: file, field: field })
                    }
                }
            }
            //
            if (!mediaFound) {
                actions.sendFields(form)
            }
        },
        sendMedia:
            ({
                form,
                file,
                field,
                lang = null,
                media = null,
                isTransPicture = false,
            }) =>
            (state, actions) => {
                let isVideoFile = false
                let isImageFile = false
                if (isDefined(file) && file !== '') {
                    if (isDefined(file.lastModified)) {
                        isImageFile = true
                    } else if (
                        typeof file === 'string' &&
                        (file.indexOf('youtube') > -1 ||
                            file.indexOf('vimeo') > -1)
                    ) {
                        isVideoFile = true
                    }
                }
                if (isVideoFile || isImageFile) {
                    if (isImageFile) {
                        Card.sendMedia(file).then((res) => {
                            actions.postSendMedia({
                                form,
                                res: res,
                                field: field,
                                lang: lang,
                                media: media,
                                isTransPicture: isTransPicture,
                            })
                        })
                    } else if (isVideoFile) {
                        Card.sendVideo({ type: 1, video: file }).then((res) => {
                            actions.postSendMedia({
                                form,
                                res: res,
                                field: field,
                                lang: lang,
                                media: media,
                                isTransPicture: isTransPicture,
                            })
                        })
                    }
                } else {
                    state.nMedia--
                    if (lang !== null) {
                        if (media !== null) {
                            if (isTransPicture) {
                                form[field][lang][media] =
                                    file !== null &&
                                    file !== undefined &&
                                    file.id !== undefined
                                        ? file.id
                                        : file
                            } else {
                                form.formDatas[lang][field][media] =
                                    file !== null &&
                                    file !== undefined &&
                                    file.id !== undefined
                                        ? file.id
                                        : file
                            }
                        } else {
                            form[field][lang] =
                                file !== null &&
                                file !== undefined &&
                                file.id !== undefined
                                    ? file.id
                                    : file
                        }
                    } else if (media !== null) {
                        form[field][media] =
                            file !== null &&
                            file !== undefined &&
                            file.id !== undefined
                                ? file.id
                                : file
                    } else {
                        form[field] =
                            file !== null &&
                            file !== undefined &&
                            file.id !== undefined
                                ? file.id
                                : file
                    }
                    if (state.nMedia <= 0) {
                        actions.sendFields(form)
                    }
                }
            },
        postSendMedia:
            ({
                form,
                res,
                field,
                lang = null,
                media = null,
                isTransPicture = false,
            }) =>
            (state, actions) => {
                state.nMedia--
                if (lang !== null) {
                    if (media !== null) {
                        if (isTransPicture) {
                            form[field][lang][media] = res.data.id
                        } else {
                            // form.formDatas[lang][field][media] = res.data.id;
                            for (var pictlang in form.formDatas) {
                                // this loop keep the same image for each same answer, on each lang
                                form.formDatas[pictlang][field][media] =
                                    res.data.id
                            }
                        }
                    } else {
                        form[field][lang] = res.data.id
                    }
                } else if (media !== null) {
                    form[field][media] = res.data.id
                } else {
                    form[field] = res.data.id
                }
                if (state.nMedia === 0) {
                    actions.sendFields(form)
                }
            },
        sendFields: (form) => (state, actions) => {
            let formTarget = state.target
            if (
                !formTarget ||
                !isDefined(formTarget) ||
                typeof formTarget !== 'function'
            ) {
                //
            } else {
                form = actions.removeEmptyFields(form)
                if (form.sendElsewhere !== undefined) {
                    formTarget = state.secondtarget
                    delete form.sendElsewhere
                }
                //
                let datas = []
                let params = formTarget
                    .toString()
                    .replace(/.*\(|\).*/gi, '')
                    .split(',')
                let modeDuplicate =
                    getQueryVariable('mode', false) === 'duplicate'
                if (!modeDuplicate) {
                    if (state.refId && state.refId !== null) {
                        datas.push(state.refId)
                    } else if (
                        state.document.id.value &&
                        state.document.id.value !== null
                    ) {
                        datas.push(state.document.id.value)
                    }
                    if (
                        state.refSId &&
                        state.refSId !== null &&
                        formTarget.toString().indexOf('session') > -1
                    ) {
                        datas.push(state.refSId)
                    }
                    if (
                        state.refSSId &&
                        state.refSSId !== null &&
                        formTarget.toString().indexOf('step') > -1
                    ) {
                        datas.push(state.refSSId)
                    }
                } else {
                    if (
                        state.document.id.value &&
                        state.document.id.value !== null
                    ) {
                        datas.push(state.document.id.value)
                    }
                }
                datas.push(form)
                //
                let refTemplate = isDefined(state.template)
                    ? state.template.value
                    : datas[1] && datas[1].template
                if (
                    state.document.id !== undefined &&
                    state.document.id.value !== null &&
                    state.document.id.value !== ''
                ) {
                    formTarget(...datas).then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.displayQueryMessage({
                                success: true,
                                type: refTemplate,
                                kind: 'update',
                            })
                            actions.afterSendFields(res.data.id)
                            if (res.data.answers) {
                                let doc = state.originaldocument
                                doc.answers = res.data.answers
                                actions.setOriginalDocument(doc)
                                actions.setDocument(state.document)
                            }
                        } else {
                            actions.displayQueryMessage({
                                success: false,
                                type: refTemplate,
                                kind: 'create',
                            })
                            actions.retrieveErrorField(res)
                        }
                    })
                } else {
                    formTarget(...datas).then((res) => {
                        if (isDefined(res.data) && !res.data.isError) {
                            actions.displayQueryMessage({
                                success: true,
                                type: refTemplate,
                                kind: 'create',
                            })
                            actions.afterSendFields(res.data.id)
                        } else {
                            actions.displayQueryMessage({
                                success: false,
                                type: refTemplate,
                                kind: 'create',
                            })
                            actions.retrieveErrorField(res)
                        }
                    })
                }
            }
        },
        getOptionnalValue: (key) => (state, actions) => {
            let optionnalObject = state.document[key],
                optionnalValue = null
            if (isDefined(optionnalObject)) {
                let optionnalType = optionnalObject.type
                if (
                    isDefined(optionnalType) &&
                    optionnalType.indexOf('queryparam') > -1
                ) {
                    let optionnalParam = optionnalType.split('/')[1]
                    if (isDefined(optionnalParam)) {
                        if (isDefined(state.match) && optionnalParam === 'id') {
                            optionnalValue = state.match.params.id
                        }
                    }
                }
            }
            if (isDefined(optionnalValue)) {
                optionnalObject.value = optionnalValue
                actions.setDocument(state.document)
            }
        },
        displayQueryMessage:
            ({ success, type, kind }) =>
            (state, actions) => {
                let queryMessage = createQueryMessage(success, type, kind)
                actions.setQueryMessage({
                    newStateKind: queryMessage.type,
                    newStateContent: queryMessage.message,
                })
            },
        retrieveErrorField: (response) => (state, actions) => {
            if (isDefined(response.data) && isDefined(response.data.extras)) {
                let errorField = response.data.extras.field
                if (isDefined(errorField)) {
                    errorField = errorField.replace(/ /, '').split('/')[0]
                    if (isDefined(errorField)) {
                        let currentdoc = state.document
                        let targetField = currentdoc[errorField]
                        if (isDefined(targetField)) {
                            targetField.validation.isValid = false
                            actions.setDocument(currentdoc)
                            actions.setFormSubmitted(true)
                        }
                    }
                }
            }
        },
        afterSendFields: (id) => (state, actions) => {
            actions.displaySuccessMessage(true)
            actions.setSubmitting(false)

            let isThereSecondFormToSend = false
            if (id !== undefined) {
                isThereSecondFormToSend = actions.sendSecondForm(id)
            }
            if (
                !isThereSecondFormToSend &&
                state.redirectAfterFormSended !== null
            ) {
                let redirect = state.redirectAfterFormSended
                if (redirect.indexOf(':ssid') > -1) {
                    redirect = redirect.replace(
                        ':ssid',
                        state.refSSId !== null ? state.refSSId : null
                    )
                    redirect = redirect.replace(
                        ':sid',
                        state.refSId !== null ? state.refSId : null
                    )
                    redirect = redirect.replace(
                        ':id',
                        state.refId !== null ? state.refId : id
                    )
                } else if (redirect.indexOf(':sid') > -1) {
                    redirect = redirect.replace(
                        ':sid',
                        state.refSId !== null ? state.refSId : null
                    )
                    redirect = redirect.replace(
                        ':id',
                        state.refId !== null ? state.refId : id
                    )
                } else if (redirect.indexOf(':id') > -1) {
                    redirect = redirect.replace(
                        ':id',
                        state.refId !== null ? state.refId : id
                    )
                }
                if (state.refType) {
                    redirect += '&type=' + state.refType
                }
                setTimeout(() => {
                    // window.main.location.go(redirect);
                    window.location.href = redirect
                }, 1500)
            } else {
                if (state.backUrl !== null && state.backUrl !== undefined) {
                    setTimeout(() => {
                        // window.location.href = state.backUrl;
                        window.main.location.go(state.backUrl)
                    }, 1500)
                }
            }
        },
        sendSecondForm: (formid) => (state, actions) => {
            let secondDocument = state.document
            secondDocument.id.value = formid
            actions.setDocument(secondDocument)
            if (
                state.secondForms &&
                state.secondForms !== null &&
                state.secondForms[0] !== undefined
            ) {
                actions.sendMedias(state.secondForms[0])
                actions.setSecondForms(null)
                return true
            } else {
                return false
            }
        },
        removeEmptyFields: (data) => (state, actions) => {
            let docData = null
            let keepEmpty = false
            for (var dat in data) {
                docData = state.document[dat]
                keepEmpty = false
                if (
                    (isDefined(docData) &&
                        [
                            'text',
                            'textarea',
                            'extra',
                            'int',
                            'alphanum',
                        ].indexOf(docData.field) > -1) ||
                    (isDefined(state.document.flags) &&
                        isDefined(
                            state.document.flags.value.filter(
                                (f) => f.key === dat
                            )[0]
                        )) ||
                    (isDefined(docData) &&
                        ((isDefined(docData.dependsOn) &&
                            docData.dependsOn.ifUnset !== undefined) ||
                            (isDefined(docData.beforeSend) &&
                                docData.beforeSend.ifempty === null)))
                ) {
                    keepEmpty = true
                }
                //
                if (
                    data[dat] !== null &&
                    typeof data[dat] == 'object' &&
                    Object.size(data[dat]) > 0 &&
                    !Array.isArray(data[dat])
                ) {
                    let newDat = actions.removeEmptyFields(data[dat])
                    data[dat] = newDat
                    if (
                        typeof data[dat] == 'object' &&
                        Object.size(data[dat]) === 0
                    ) {
                        delete data[dat]
                    }
                }
                if (data[dat] == 'false') {
                    data[dat] = false
                } else if (data[dat] == 'true') {
                    data[dat] = true
                }
                if (dat != 'expression') {
                    if (data[dat] === '' || data[dat] === null) {
                        if (
                            (dat == 'customer' || dat == 'parent') &&
                            data[dat] === ''
                        ) {
                            data[dat] = false
                        } else if (
                            dat !== 'parent' &&
                            (data[dat] === null || data[dat] === '')
                        ) {
                            if (keepEmpty) {
                                continue
                            } else {
                                delete data[dat] // delete only null and empty (if we dont send empty, we cant remove a text field content)
                            }
                        }
                    } else if (Array.isArray(data[dat])) {
                        for (var i = data[dat].length - 1; i >= 0; i--) {
                            if (data[dat][i] === '') {
                                // data[dat].splice(i, 1);
                            }
                        }
                    }
                }
            }

            return data
        },
        onPreviewForm: () => (state, actions) => {
            actions.setPreviewDisplayed(true)
            let doc = deepCopy(state.document)
            let form = actions.updateValidations(doc)
            let parsedForm = actions.parseDocumentToBeSended(deepCopy(form))
            parsedForm = actions.removeEmptyFields(parsedForm)
            if (isDefined(state.document.template)) {
                let parsedType = parsedForm.type
                if (!isDefined(parsedType)) {
                    if (state.document.template.value === 'recipe') {
                        parsedType = 19
                    } else if (state.document.template.value === 'article') {
                        parsedType = 18
                    } else if (state.document.template.value === 'citation') {
                        parsedType = 20
                    } else if (state.document.template.value === 'prenom') {
                        parsedType = 8
                    } else {
                        parsedType = state.document.template.value
                        if (state.document.template.value === 'program') {
                            if (isDefined(state.document.original.sessions)) {
                                parsedForm.sessions =
                                    state.document.original.sessions
                            }
                            if (
                                isDefined(state.document.original.sessionNumber)
                            ) {
                                parsedForm.sessionNumber =
                                    state.document.original.sessionNumber
                            }
                        }
                    }
                } else {
                    if (
                        parsedType &&
                        isDefined(state.document.original.equipments) &&
                        isDefined(state.document.original.steps)
                    ) {
                        parsedType = 'program-session'
                        parsedForm.programid = state.refId
                        parsedForm.sessionid = state.refSId
                        if (
                            !isDefined(parsedForm.steps) &&
                            isDefined(state.document.original)
                        ) {
                            parsedForm.steps = state.document.original.steps
                        }
                    }
                }
                parsedForm.type = parsedType
                parsedForm.displayedLang = state.displayedLang
            }
            if (doc.media && doc.media.base64) {
                parsedForm.media = doc.media.base64
            }
            if (doc.mediaTranslations && doc.mediaTranslations.base64) {
                parsedForm.media = doc.mediaTranslations.base64
            }

            let then = () => {
                //
                var iframe = document.getElementById('myFrame')
                iframe.contentWindow.postMessage(
                    JSON.stringify(parsedForm),
                    APP_FRONT_WEB_URL
                )
            }

            if (isDefined(parsedForm.theme)) {
                for (var typ in constants.custo.CARDS_TYPES) {
                    if (
                        constants.custo.CARDS_TYPES[typ].key ===
                        +parsedForm.theme
                    ) {
                        parsedForm.valueTheme =
                            constants.custo.CARDS_TYPES[typ].theme
                        parsedForm.labelTheme =
                            constants.custo.CARDS_TYPES[typ].value
                    }
                }
            }

            if (isDefined(parsedForm.coachId)) {
                Card.getCoach(parsedForm.coachId).then((res) => {
                    if (isDefined(res.data) && !res.data.isError) {
                        parsedForm.coach = res.data
                    }
                    then()
                })
            } else {
                then()
            }
        },
        onClosePreviewForm: () => (state, actions) => {
            var iframe = document.getElementById('myFrame')
            iframe.contentWindow.postMessage(null, APP_FRONT_WEB_URL)
            actions.setPreviewDisplayed(false)
        },
        setPreviewDisplayed: (newState) => (state) => ({
            previewDisplayed: newState,
        }),
        resiliateUser: (id) => (state, actions) => {
            User.resiliateUser(id).then(() => {
                setTimeout(() => {
                    window.location.href = state.backUrl
                }, 500)
            })
        },
        setEditType: (newState) => (state) => ({
            editType: newState,
        }),
        setQueryMessage:
            ({ newStateKind, newStateContent }) =>
            (state) => ({
                messageKind: newStateKind,
                messageContent: newStateContent,
            }),
        setDocument: (newState) => (state) => ({
            document: newState,
        }),
        setTarget: (newState) => (state) => ({
            target: newState,
        }),
        setSecondTarget: (newState) => (state) => ({
            secondtarget: newState,
        }),
        setBackUrl: (newState) => (state) => ({
            backUrl: newState,
        }),
        setSecondForms: (newState) => (state) => ({
            secondForms: newState,
        }),
        setSubmitting: (newState) => (state) => ({
            formIsSubmitting: newState,
        }),
        setFormSubmitted: (newState) => (state) => ({
            formSubmitted: newState,
        }),
        setKPIList: (newState) => (state) => ({
            kpiList: newState,
        }),
        setCoachs: (newState) => (state) => ({
            coachsList: newState,
        }),
        setCompareList: (newState) => (state) => ({
            compareList: newState,
        }),
        setDataTags: (newState) => (state) => ({
            dataTags: newState,
        }),
        setCiqualFamily: (newState) => (state) => ({
            ciqualFamily: newState,
        }),
        displaySuccessMessage: (newState) => (state) => ({
            messageActive: newState,
        }),
        setRefId: (newState) => (state) => ({
            refId: newState,
        }),
        setRefSId: (newState) => (state) => ({
            refSId: newState,
        }),
        setRefSSId: (newState) => (state) => ({
            refSSId: newState,
        }),
        setRefType: (newState) => (state) => ({
            refType: newState,
        }),
        setPreviousPage: (newState) => (state) => ({
            previousPage: newState,
        }),
        setOriginalDocument: (newState) => (state) => ({
            originaldocument: newState,
        }),
        setDisplayedLang: (newState) => (state) => ({
            displayedLang: newState,
        }),
        setPreviewEnable: (newState) => (state) => ({
            previewEnable: newState,
        }),
        setMatch: (newState) => (state) => ({
            match: newState,
        }),
        setOverridecustomer: (newState) => (state) => ({
            overridecustomer: newState,
        }),
        updateSearchBar: (newState) => (state) => ({
            searchbar: newState,
        }),
    },
    (state, actions) => (props, children) =>
        (
            <div class={'btzAdminForm'}>
                {state.document !== null && (
                    <Form
                        classes={'col-xs-12 sup-custom-form btzForm'}
                        onsubmit={(event) => event.preventDefault()}
                        autocomplete="off"
                    >
                        {/*<input type='hidden' name='template' value={(props.card !== undefined ? props.card.template : (state.document.template !== undefined ? state.document.template.value : ''))} oncreate={(el) => {actions.updateDocument(el)}} />
                    {state.document.id &&
                        <input type='hidden' name='id' value={state.document.id.value} oncreate={(el) => {actions.updateDocument(el)}} />
                    }*/}

                        {state.messageActive && (
                            <MessagePopup
                                kind={state.messageKind}
                                active={'active'}
                                duration={3000}
                                durationCallback={() =>
                                    actions.displaySuccessMessage(false)
                                }
                                message={state.messageContent}
                            ></MessagePopup>
                        )}

                        <Form.Group
                            classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits btzForm-btzTop-btzSubmits"
                            style={{ 'text-align': 'right' }}
                        >
                            <div class="col-6 p-0">
                                {props.header && (
                                    <p class="sup-title">
                                        {t(props.header[0])}
                                        <font>
                                            {props.header.length > 1
                                                ? t(props.header[1])
                                                : ''}
                                        </font>
                                    </p>
                                )}
                            </div>
                            <div class="col-6 p-0">
                                {state.previewEnable && (
                                    <Button
                                        primary
                                        flat
                                        onclick={() => {
                                            actions.onPreviewForm()
                                        }}
                                    >
                                        {t('Aperçu')}
                                    </Button>
                                )}
                                <Button
                                    primary
                                    flat
                                    cancel
                                    onclick={() => {
                                        window.location.href = state.backUrl
                                    }}
                                >
                                    {t('Annuler')}
                                </Button>
                                {props.canBeResiliated === true && (
                                    <Button
                                        primary
                                        flat
                                        active
                                        dangerous
                                        loading={state.formIsSubmitting}
                                        onclick={() => {
                                            actions.resiliateUser(
                                                props.originaldocument.id
                                            )
                                        }}
                                    >
                                        {t('Resilier')}
                                    </Button>
                                )}
                                {props.canBeDeleted === true && (
                                    <Button
                                        primary
                                        flat
                                        active
                                        dangerous
                                        loading={state.formIsSubmitting}
                                        onclick={() => {
                                            props
                                                .deleteAction(
                                                    props.originaldocument.id
                                                )
                                                .then(() => {
                                                    window.location.href =
                                                        state.backUrl
                                                })
                                        }}
                                    >
                                        {t('Supprimer')}
                                    </Button>
                                )}
                                <Button
                                    primary
                                    flat
                                    active
                                    loading={state.formIsSubmitting}
                                    onclick={actions.sendForm}
                                >
                                    {t('Sauvegarder')}
                                </Button>
                            </div>
                            <div class="col-12 p-0">
                                {state.previousPage && (
                                    <p class="sup-title">
                                        {t('Revenir') + ' '}
                                        {state.previousPage.map((pp) => (
                                            <dummy
                                                style={{
                                                    'margin-right': '5px',
                                                    'cursor': 'pointer',
                                                    'text-decoration':
                                                        'underline',
                                                }}
                                                onclick={() => {
                                                    if (pp.url !== null) {
                                                        // window.main.location.go(pp.url.replace(':id', state.refId).replace(':sid', state.refSId));
                                                        window.location.href =
                                                            pp.url
                                                                .replace(
                                                                    ':id',
                                                                    state.refId
                                                                )
                                                                .replace(
                                                                    ':sid',
                                                                    state.refSId
                                                                )
                                                    } else {
                                                        window.history.back()
                                                    }
                                                }}
                                            >
                                                {'< ' + pp.label}
                                            </dummy>
                                        ))}
                                    </p>
                                )}
                            </div>
                        </Form.Group>

                        {props.withTopSearchBar === true &&
                            state.overridecustomer === 'oncoflow' && (
                                <Form class={'sup-topsearchbar'}>
                                    <p class={'sup-topsearchbar-title'}>
                                        {t(
                                            "Ajoutez un compte soignant en le recherchant dans l'annuaire des professionnels de santé"
                                        )}
                                    </p>
                                    <div
                                        class={'sup-group-form'}
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                        }}
                                    >
                                        <Form.Group classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits">
                                            <input
                                                key={'field-top-search-bar'}
                                                type={'text'}
                                                name={'searchbar'}
                                                placeholder={t(
                                                    'Renseignez son RPPS, ADELI ou nom de famille'
                                                )}
                                                value={state.searchbar.value}
                                                oninput={
                                                    actions.updateASDoctorSearch
                                                }
                                            />
                                            {isDefined(
                                                state.searchbar.results
                                            ) &&
                                                state.searchbar.results.length >
                                                    0 && (
                                                    <div
                                                        class={
                                                            'sup-topsearchbar-results'
                                                        }
                                                    >
                                                        {state.searchbar.results.map(
                                                            (result) => (
                                                                <div
                                                                    class={
                                                                        'sup-topsearchbar-result'
                                                                    }
                                                                    onclick={() => {
                                                                        actions.selectSearchResult(
                                                                            result
                                                                        )
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {result[
                                                                            "Nom d'exercice"
                                                                        ] +
                                                                            ' ' +
                                                                            (isDefined(
                                                                                result[
                                                                                    "Prénom d'exercice"
                                                                                ],
                                                                                true,
                                                                                true
                                                                            )
                                                                                ? result[
                                                                                      "Prénom d'exercice"
                                                                                  ]
                                                                                      .toLowerCase()
                                                                                      .charAt(
                                                                                          0
                                                                                      )
                                                                                      .toUpperCase() +
                                                                                  result[
                                                                                      "Prénom d'exercice"
                                                                                  ]
                                                                                      .toLowerCase()
                                                                                      .slice(
                                                                                          1
                                                                                      )
                                                                                : '') +
                                                                            ', '}
                                                                    </span>
                                                                    <span>
                                                                        {isDefined(
                                                                            result[
                                                                                'Libellé profession'
                                                                            ],
                                                                            true,
                                                                            true
                                                                        )
                                                                            ? result[
                                                                                  'Libellé profession'
                                                                              ] +
                                                                              ', '
                                                                            : ''}
                                                                    </span>
                                                                    <span>
                                                                        {isDefined(
                                                                            result[
                                                                                'Libellé savoir-faire'
                                                                            ],
                                                                            true,
                                                                            true
                                                                        )
                                                                            ? result[
                                                                                  'Libellé savoir-faire'
                                                                              ]
                                                                            : ''}
                                                                    </span>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            {isDefined(
                                                state.searchbar.results
                                            ) &&
                                                state.searchbar.results
                                                    .length === 0 && (
                                                    <div
                                                        class={
                                                            'sup-topsearchbar-results'
                                                        }
                                                    >
                                                        <div
                                                            class={
                                                                'sup-topsearchbar-result'
                                                            }
                                                        >
                                                            <span style="color: #FF3B30;font-weight: initial;">
                                                                {t(
                                                                    "Aucun professionnel trouvé, veuillez vérifier l'identifiant ou le nom saisi."
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                        </Form.Group>
                                    </div>
                                    <Form.Group classes="btzForm-btzFooter">
                                        <Button
                                            primary
                                            flat
                                            active
                                            loading={state.formIsSubmitting}
                                            onclick={actions.searchASDoctor}
                                        >
                                            {t('Rechercher')}
                                        </Button>
                                        <Button
                                            primary
                                            flat
                                            cancel
                                            onclick={
                                                actions.clearASDoctorSearch
                                            }
                                        >
                                            {t('Reset')}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            )}

                        <div key={'main-form'}>
                            {Object.keys(state.document)
                                .filter(
                                    (f) =>
                                        isDefined(state.document[f]) &&
                                        state.document[f].type !==
                                            'translation' &&
                                        state.document[f].field !==
                                            'translation' &&
                                        state.document[f].field !== 'tabs' &&
                                        state.document[f].field !== 'scenarios'
                                )
                                .map((key, idx) => (
                                    <dummy style={{ 'vertical-align': 'top' }}>
                                        {/*
                                {((state.document[key].dependsOn === undefined) || ((state.document[key].dependsOn !== undefined) && ((state.document[state.document[key].dependsOn.field].value == state.document[key].dependsOn.value) || (Array.isArray(state.document[key].dependsOn.value) && (state.document[key].dependsOn.value.map((m) => (state.document[state.document[key].dependsOn.field].value.indexOf(m) > -1)))) || (Array.isArray(state.document[state.document[key].dependsOn.field].value) && (state.document[state.document[key].dependsOn.field].value.indexOf(state.document[key].dependsOn.value) > -1) && (!isDefined(state.document[key].dependsOn.no) || (isDefined(state.document[key].dependsOn.no) && (state.document[state.document[key].dependsOn.field].value.indexOf(state.document[key].dependsOn.no) === -1))))))) &&
                                 */}
                                        {state.document[key].forceHidden !==
                                            true &&
                                            (state.document[key].dependsOn ===
                                                undefined ||
                                                (state.document[key]
                                                    .dependsOn !== undefined &&
                                                    !Array.isArray(
                                                        state.document[key]
                                                            .dependsOn
                                                    ) &&
                                                    (isDefined(
                                                        state.document[key]
                                                            .dependsOn.is
                                                    ) ||
                                                        (isDefined(
                                                            state.document[
                                                                state.document[
                                                                    key
                                                                ].dependsOn
                                                                    .field
                                                            ]
                                                        ) &&
                                                            state.document[
                                                                state.document[
                                                                    key
                                                                ].dependsOn
                                                                    .field
                                                            ].value ==
                                                                state.document[
                                                                    key
                                                                ].dependsOn
                                                                    .value) ||
                                                        (Array.isArray(
                                                            state.document[key]
                                                                .dependsOn.value
                                                        ) &&
                                                            isDefined(
                                                                state.document[
                                                                    key
                                                                ].dependsOn.value.filter(
                                                                    (m) =>
                                                                        isDefined(
                                                                            state
                                                                                .document[
                                                                                state
                                                                                    .document[
                                                                                    key
                                                                                ]
                                                                                    .dependsOn
                                                                                    .field
                                                                            ]
                                                                        ) &&
                                                                        state.document[
                                                                            state
                                                                                .document[
                                                                                key
                                                                            ]
                                                                                .dependsOn
                                                                                .field
                                                                        ].value.indexOf(
                                                                            m
                                                                        ) > -1
                                                                )[0]
                                                            )) ||
                                                        (isDefined(
                                                            state.document[
                                                                state.document[
                                                                    key
                                                                ].dependsOn
                                                                    .field
                                                            ]
                                                        ) &&
                                                            Array.isArray(
                                                                state.document[
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].dependsOn
                                                                        .field
                                                                ].value
                                                            ) &&
                                                            state.document[
                                                                state.document[
                                                                    key
                                                                ].dependsOn
                                                                    .field
                                                            ].value.indexOf(
                                                                state.document[
                                                                    key
                                                                ].dependsOn
                                                                    .value
                                                            ) > -1)) &&
                                                    (!isDefined(
                                                        state.document[key]
                                                            .dependsOn.no
                                                    ) ||
                                                        (isDefined(
                                                            state.document[key]
                                                                .dependsOn.no
                                                        ) &&
                                                            state.document[
                                                                state.document[
                                                                    key
                                                                ].dependsOn
                                                                    .field
                                                            ].value.indexOf(
                                                                state.document[
                                                                    key
                                                                ].dependsOn.no
                                                            ) === -1)) &&
                                                    (!isDefined(
                                                        state.document[key]
                                                            .dependsOn.is
                                                    ) ||
                                                        (isDefined(
                                                            state.document[key]
                                                                .dependsOn.is
                                                        ) &&
                                                            String(
                                                                state.document[
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].dependsOn
                                                                        .field
                                                                ].value
                                                            ) ===
                                                                String(
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].dependsOn
                                                                        .is
                                                                )))) ||
                                                (Array.isArray(
                                                    state.document[key]
                                                        .dependsOn
                                                ) &&
                                                    (state.document[
                                                        key
                                                    ].dependsOn.filter((on) =>
                                                        isDefined(on.is)
                                                    ).length === 0 ||
                                                        (state.document[
                                                            key
                                                        ].dependsOn.filter(
                                                            (on) =>
                                                                isDefined(on.is)
                                                        ).length > 0 &&
                                                            state.document[
                                                                key
                                                            ].dependsOn.reduce(
                                                                (acc, on) => {
                                                                    if (
                                                                        isDefined(
                                                                            state
                                                                                .document[
                                                                                on
                                                                                    .field
                                                                            ]
                                                                        ) &&
                                                                        String(
                                                                            state
                                                                                .document[
                                                                                on
                                                                                    .field
                                                                            ]
                                                                                .value
                                                                        ) !==
                                                                            String(
                                                                                on.is
                                                                            )
                                                                    ) {
                                                                        acc.push(
                                                                            on
                                                                        )
                                                                    }
                                                                    //
                                                                    return acc
                                                                },
                                                                []
                                                            ).length === 0))) ||
                                                (isDefined(
                                                    state.document[key]
                                                        .dependsOn
                                                ) &&
                                                    isDefined(
                                                        state.document[key]
                                                            .dependsOn
                                                            .documentExist
                                                    ) &&
                                                    isDefined(
                                                        state.document.id.value,
                                                        true
                                                    ))) && (
                                                <div
                                                    key={
                                                        state.document[key]
                                                            .forceHidden +
                                                        '-' +
                                                        idx
                                                    }
                                                    class={
                                                        'sup-group-form ' +
                                                        state.document[key]
                                                            .field +
                                                        ' ' +
                                                        'input-' +
                                                        state.document[key]
                                                            .name +
                                                        ' ' +
                                                        (state.document[key]
                                                            .display &&
                                                        state.document[key]
                                                            .display.border ===
                                                            'bottom'
                                                            ? 'border-bottom'
                                                            : '') +
                                                        ' ' +
                                                        (state.document[key]
                                                            .display &&
                                                        state.document[key]
                                                            .display.block &&
                                                        state.document[key]
                                                            .display.block.left
                                                            ? `padding-left-${state.document[key].display.block.left}`
                                                            : '') +
                                                        ' ' +
                                                        (state.document[key]
                                                            .display &&
                                                        state.document[key]
                                                            .display.mode ===
                                                            'half'
                                                            ? 'col-6 col-xs-12'
                                                            : 'col-12') +
                                                        (state.formSubmitted &&
                                                        state.document[key]
                                                            .validation !==
                                                            undefined &&
                                                        state.document[key]
                                                            .validation
                                                            .isValid === false
                                                            ? ' field-is-in-error'
                                                            : '')
                                                    }
                                                    style={{
                                                        display:
                                                            state.document[key]
                                                                .field ===
                                                            'hidden'
                                                                ? 'none'
                                                                : 'inline-block',
                                                    }}
                                                >
                                                    {state.document[key]
                                                        .display &&
                                                    state.document[key].display
                                                        .block &&
                                                    state.document[key].display
                                                        .block.title ? (
                                                        <div
                                                            class={
                                                                'sup-group-form-title' +
                                                                ' ' +
                                                                (state.document[
                                                                    key
                                                                ].display.block
                                                                    .left
                                                                    ? `margin-negative-left-${state.document[key].display.block.left}`
                                                                    : '')
                                                            }
                                                        >
                                                            {
                                                                state.document[
                                                                    key
                                                                ].display.block
                                                                    .title
                                                            }
                                                        </div>
                                                    ) : null}
                                                    {state.document[key]
                                                        .label !== '' &&
                                                        state.document[key]
                                                            .label !==
                                                            undefined && (
                                                            <div
                                                                style={{
                                                                    'display':
                                                                        'inline-block',
                                                                    'vertical-align':
                                                                        'top',
                                                                }}
                                                            >
                                                                <label
                                                                    class={
                                                                        'sup-label ' +
                                                                        (state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .display &&
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .display
                                                                            .mode ===
                                                                            'half'
                                                                            ? 'width-half'
                                                                            : 'width-full')
                                                                    }
                                                                >
                                                                    {t(
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ].label
                                                                    ) +
                                                                        (state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .validation !==
                                                                            undefined &&
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .validation
                                                                            .mandatory ===
                                                                            true &&
                                                                        (!isDefined(
                                                                            state
                                                                                .document[
                                                                                key
                                                                            ]
                                                                                .validation
                                                                                .notmandatory
                                                                        ) ||
                                                                            (isDefined(
                                                                                state
                                                                                    .document[
                                                                                    key
                                                                                ]
                                                                                    .validation
                                                                                    .notmandatory
                                                                            ) &&
                                                                                state.document[
                                                                                    key
                                                                                ].validation.notmandatory.indexOf(
                                                                                    state.overridecustomer
                                                                                ) ===
                                                                                    -1))
                                                                            ? '*'
                                                                            : '')}
                                                                    {state
                                                                        .document[
                                                                        key
                                                                    ]
                                                                        .labelinfo !==
                                                                        '' &&
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .labelinfo !==
                                                                            undefined && (
                                                                            <font class="">
                                                                                {t(
                                                                                    state
                                                                                        .document[
                                                                                        key
                                                                                    ]
                                                                                        .labelinfo
                                                                                )}
                                                                            </font>
                                                                        )}
                                                                </label>
                                                                {isDefined(
                                                                    state.editType
                                                                ) && (
                                                                    <AdminFormHelp
                                                                        editType={
                                                                            state.editType
                                                                        }
                                                                        type={
                                                                            state
                                                                                .document[
                                                                                key
                                                                            ]
                                                                                .type
                                                                        }
                                                                        infos={
                                                                            state
                                                                                .document[
                                                                                key
                                                                            ]
                                                                                .infos
                                                                        }
                                                                    ></AdminFormHelp>
                                                                )}
                                                            </div>
                                                        )}
                                                    {state.document[key]
                                                        .field ===
                                                        'textarea' && (
                                                        <div
                                                            class="sup-textarea"
                                                            style={{
                                                                'margin-left':
                                                                    '0px',
                                                            }}
                                                        >
                                                            <textarea
                                                                key={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].name +
                                                                    '-' +
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].field
                                                                }
                                                                name={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].name
                                                                }
                                                                class={
                                                                    'input-text ' +
                                                                    (state
                                                                        .document[
                                                                        key
                                                                    ].value ===
                                                                        undefined ||
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].value ===
                                                                        null
                                                                        ? 'undefined'
                                                                        : state
                                                                              .document[
                                                                              key
                                                                          ]
                                                                              .value)
                                                                }
                                                                placeholder={t(
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                )}
                                                                oninput={
                                                                    actions.updateDocument
                                                                }
                                                                readonly={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ]
                                                                        .readonly ===
                                                                    true
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                {
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].value
                                                                }
                                                            </textarea>
                                                        </div>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'dropdown' && (
                                                        <AdminFormDropDown
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original ||
                                                                state.originaldocument
                                                            }
                                                            wholeDocument={
                                                                state.document
                                                            }
                                                            list={
                                                                state.document[
                                                                    key
                                                                ].list
                                                            }
                                                            coachsList={
                                                                state.coachsList
                                                            }
                                                            kpiList={
                                                                state.kpiList
                                                            }
                                                            dataTags={
                                                                state.dataTags
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                            readonly={
                                                                state.document[
                                                                    key
                                                                ].readonly ===
                                                                true
                                                                    ? true
                                                                    : false
                                                            }
                                                        ></AdminFormDropDown>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'linked-dropdown' && (
                                                        <AdminFormLinkedDropDown
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            list={
                                                                state.document[
                                                                    key
                                                                ].list
                                                            }
                                                            kpiList={
                                                                state.kpiList
                                                            }
                                                            dataTags={
                                                                state.dataTags
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormLinkedDropDown>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'checkbox' && (
                                                        <AdminFormCheckBox
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            oninput={
                                                                actions.updateDocument
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormCheckBox>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'input-to-textarea' && (
                                                        <AdminFormWithInputToTextarea
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            kpiList={
                                                                state.kpiList
                                                            }
                                                            oninput={
                                                                actions.updateDocument
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormWithInputToTextarea>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'dyninput-to-div' && (
                                                        <AdminFormWithDynInputToDiv
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            kpiList={
                                                                state.kpiList
                                                            }
                                                            oninput={
                                                                actions.updateDocument
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormWithDynInputToDiv>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'dropdown-to-div' && (
                                                        <AdminFormWithDropDownToDiv
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            kpiList={
                                                                state.kpiList
                                                            }
                                                            ciqualFamily={
                                                                state.ciqualFamily
                                                            }
                                                            oninput={
                                                                actions.updateDocument
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormWithDropDownToDiv>
                                                    )}
                                                    {state.document[key]
                                                        .field === 'date' && (
                                                        <AdminFormDate
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original
                                                            }
                                                            compareList={
                                                                state.compareList
                                                            }
                                                            oninput={
                                                                actions.updateDocument
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormDate>
                                                    )}
                                                    {state.document[key]
                                                        .field === 'radio' && (
                                                        <AdminFormRadio
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                            readonly={
                                                                state.document[
                                                                    key
                                                                ].readonly ===
                                                                true
                                                                    ? true
                                                                    : false
                                                            }
                                                        ></AdminFormRadio>
                                                    )}
                                                    {state.document[key]
                                                        .field === 'media' && (
                                                        <AdminFormMedia
                                                            key={
                                                                'field-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                            cantChooseMedia={
                                                                state.document[
                                                                    key
                                                                ]
                                                                    .cantChooseMedia
                                                            }
                                                        ></AdminFormMedia>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'pictures' && (
                                                        <AdminFormPictures
                                                            key={
                                                                'field-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormPictures>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'listing' && (
                                                        <AdminFormListing
                                                            key={
                                                                'field-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original
                                                            }
                                                        ></AdminFormListing>
                                                    )}
                                                    {state.document[key]
                                                        .field === 'topic' && (
                                                        <AdminFormForumTopic
                                                            key={
                                                                'topic-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormForumTopic>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'arrayOfEval' && (
                                                        <AdminFormArrayOfEvalMaker
                                                            key={
                                                                'topic-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormArrayOfEvalMaker>
                                                    )}
                                                    {state.document[key]
                                                        .field === 'teams' && (
                                                        <AdminFormTeams
                                                            key={
                                                                'topic-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original
                                                            }
                                                        ></AdminFormTeams>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'verify-with-query' && (
                                                        <AdminFormVerifyWithQuery
                                                            key={
                                                                'verify-with-query-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original
                                                            }
                                                            oninput={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormVerifyWithQuery>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'display-from-query' && (
                                                        <AdminFormDisplayFromQuery
                                                            key={
                                                                'verify-with-query-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original
                                                            }
                                                            oninput={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormDisplayFromQuery>
                                                    )}
                                                    {[
                                                        'int',
                                                        'alphanum',
                                                        'phone',
                                                    ].includes(
                                                        state.document[key]
                                                            .field
                                                    ) && (
                                                        <dummy>
                                                            <input
                                                                key={
                                                                    'field-' +
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].name
                                                                }
                                                                type={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].field
                                                                }
                                                                name={key}
                                                                value={
                                                                    isDefined(
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ].value
                                                                    )
                                                                        ? Array.isArray(
                                                                              state
                                                                                  .document[
                                                                                  key
                                                                              ]
                                                                                  .value
                                                                          )
                                                                            ? state.document[
                                                                                  key
                                                                              ].value.join(
                                                                                  ''
                                                                              )
                                                                            : state
                                                                                  .document[
                                                                                  key
                                                                              ]
                                                                                  .value
                                                                        : ''
                                                                }
                                                                oninput={
                                                                    actions.updateDocument
                                                                }
                                                                placeholder={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ]
                                                                        .placeholderInner ||
                                                                    ''
                                                                }
                                                                readonly={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ]
                                                                        .readonly ===
                                                                    true
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            {state.document[key]
                                                                .placeholder && (
                                                                <p
                                                                    class=""
                                                                    style={{
                                                                        display:
                                                                            'inline-block',
                                                                        margin: '0 0 0 15px',
                                                                    }}
                                                                >
                                                                    {t(
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .placeholder
                                                                    )}
                                                                </p>
                                                            )}
                                                        </dummy>
                                                    )}
                                                    {state.document[key]
                                                        .field === 'array' && (
                                                        <input
                                                            key={
                                                                'field-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            type={'array'}
                                                            name={key}
                                                            value={
                                                                state.document[
                                                                    key
                                                                ].value !==
                                                                undefined
                                                                    ? state.document[
                                                                          key
                                                                      ].value.join(
                                                                          ','
                                                                      )
                                                                    : ''
                                                            }
                                                            oninput={
                                                                actions.updateDocument
                                                            }
                                                        />
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'readonly' && (
                                                        <dummy>
                                                            {state.document[key]
                                                                .type ===
                                                            'array' ? (
                                                                <input
                                                                    key={
                                                                        'field-' +
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ].name
                                                                    }
                                                                    type={
                                                                        'array'
                                                                    }
                                                                    name={key}
                                                                    value={
                                                                        state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .value !==
                                                                        undefined
                                                                            ? state.document[
                                                                                  key
                                                                              ].value.join(
                                                                                  ', '
                                                                              )
                                                                            : ''
                                                                    }
                                                                    readOnly
                                                                />
                                                            ) : (
                                                                <dummy>
                                                                    {state
                                                                        .document[
                                                                        key
                                                                    ].type ===
                                                                    'date' ? (
                                                                        <AdminFormDate
                                                                            name={
                                                                                key
                                                                            }
                                                                            document={
                                                                                state
                                                                                    .document[
                                                                                    key
                                                                                ]
                                                                            }
                                                                            original={
                                                                                state
                                                                                    .document
                                                                                    .original
                                                                            }
                                                                            compareList={
                                                                                state.compareList
                                                                            }
                                                                            readonly={
                                                                                true
                                                                            }
                                                                        ></AdminFormDate>
                                                                    ) : (
                                                                        <input
                                                                            key={
                                                                                'field-' +
                                                                                state
                                                                                    .document[
                                                                                    key
                                                                                ]
                                                                                    .name
                                                                            }
                                                                            type={
                                                                                'text'
                                                                            }
                                                                            name={
                                                                                key
                                                                            }
                                                                            value={
                                                                                state
                                                                                    .document[
                                                                                    key
                                                                                ]
                                                                                    .value
                                                                            }
                                                                            readOnly
                                                                        />
                                                                    )}
                                                                </dummy>
                                                            )}
                                                        </dummy>
                                                    )}
                                                    {state.document[key]
                                                        .field === 'ssos' &&
                                                        Array.isArray(
                                                            state.document[key]
                                                                .value
                                                        ) && (
                                                            <dummy>
                                                                {isDefined(
                                                                    state.originaldocument
                                                                ) &&
                                                                state
                                                                    .originaldocument
                                                                    .customer !==
                                                                    null &&
                                                                state.document[
                                                                    key
                                                                ].value.filter(
                                                                    (f) =>
                                                                        f.name ===
                                                                        (state
                                                                            .document[
                                                                            key
                                                                        ]
                                                                            .substitute ||
                                                                            state
                                                                                .originaldocument
                                                                                .customer
                                                                                .ssoName)
                                                                )[0] !==
                                                                    undefined ? (
                                                                    <input
                                                                        key={
                                                                            'field-' +
                                                                            state
                                                                                .document[
                                                                                key
                                                                            ]
                                                                                .name
                                                                        }
                                                                        type={
                                                                            'text'
                                                                        }
                                                                        name={
                                                                            key
                                                                        }
                                                                        value={
                                                                            state.document[
                                                                                key
                                                                            ].value.filter(
                                                                                (
                                                                                    f
                                                                                ) =>
                                                                                    f.name ===
                                                                                    (state
                                                                                        .document[
                                                                                        key
                                                                                    ]
                                                                                        .substitute ||
                                                                                        state
                                                                                            .originaldocument
                                                                                            .customer
                                                                                            .ssoName)
                                                                            )[0]
                                                                                .id
                                                                        }
                                                                        readOnly
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        key={
                                                                            'field-' +
                                                                            state
                                                                                .document[
                                                                                key
                                                                            ]
                                                                                .name
                                                                        }
                                                                        type={
                                                                            'text'
                                                                        }
                                                                        name={
                                                                            key
                                                                        }
                                                                        value={
                                                                            isDefined(
                                                                                state.document[
                                                                                    key
                                                                                ].value.filter(
                                                                                    (
                                                                                        f
                                                                                    ) =>
                                                                                        f.name ===
                                                                                        state
                                                                                            .document[
                                                                                            key
                                                                                        ]
                                                                                            .substitute
                                                                                )[0]
                                                                            )
                                                                                ? state.document[
                                                                                      key
                                                                                  ].value.filter(
                                                                                      (
                                                                                          f
                                                                                      ) =>
                                                                                          f.name ===
                                                                                          state
                                                                                              .document[
                                                                                              key
                                                                                          ]
                                                                                              .substitute
                                                                                  )[0]
                                                                                      .id
                                                                                : ''
                                                                        }
                                                                        readonly={
                                                                            state
                                                                                .document[
                                                                                key
                                                                            ]
                                                                                .readonly ===
                                                                            true
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        oninput={
                                                                            actions.updateDocumentRpps
                                                                        }
                                                                    />
                                                                )}
                                                            </dummy>
                                                        )}
                                                    {[
                                                        'array-of-ordered-linked',
                                                        'phone',
                                                        'verify-with-query',
                                                        'display-from-query',
                                                        'textarea',
                                                        'dropdown',
                                                        'linked-dropdown',
                                                        'linked-selects',
                                                        'radio',
                                                        'checkbox',
                                                        'input-to-textarea',
                                                        'dyninput-to-div',
                                                        'dropdown-to-div',
                                                        'media',
                                                        'pictures',
                                                        'date',
                                                        'int',
                                                        'alphanum',
                                                        'readonly',
                                                        'listing',
                                                        'ssos',
                                                        'comment',
                                                        'topic',
                                                        'array',
                                                        'answers',
                                                        'arrayOfEval',
                                                        'teams',
                                                        'scenarios',
                                                    ].indexOf(
                                                        state.document[key]
                                                            .field
                                                    ) === -1 &&
                                                        state.document[key]
                                                            .field !==
                                                            undefined && (
                                                            <input
                                                                key={
                                                                    'field-' +
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].name
                                                                }
                                                                type={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].field
                                                                }
                                                                name={key}
                                                                placeholder={t(
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ]
                                                                        .placeholder
                                                                )}
                                                                value={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ].value
                                                                }
                                                                oninput={
                                                                    actions.updateDocument
                                                                }
                                                                oncreate={() => {
                                                                    actions.getOptionnalValue(
                                                                        key
                                                                    )
                                                                }}
                                                                readonly={
                                                                    state
                                                                        .document[
                                                                        key
                                                                    ]
                                                                        .readonly ===
                                                                    true
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                    {state.document[key]
                                                        .field ===
                                                        'linked-selects' && (
                                                        <AdminFormLinkedSelects
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            wholeDocument={
                                                                state.document
                                                            }
                                                            list={
                                                                state.document[
                                                                    key
                                                                ].list
                                                            }
                                                            kpiList={
                                                                state.kpiList
                                                            }
                                                            dataTags={
                                                                state.dataTags
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormLinkedSelects>
                                                    )}
                                                    {state.document[key]
                                                        .field ===
                                                        'array-of-ordered-linked' && (
                                                        <AdminFormArrayOfOrderedLinked
                                                            key={
                                                                'array-of-ordered-linked-' +
                                                                state.document[
                                                                    key
                                                                ].name
                                                            }
                                                            list={'dynamic'}
                                                            name={key}
                                                            document={
                                                                state.document[
                                                                    key
                                                                ]
                                                            }
                                                            original={
                                                                state.document
                                                                    .original
                                                            }
                                                            onclick={
                                                                actions.updateDocument
                                                            }
                                                        ></AdminFormArrayOfOrderedLinked>
                                                    )}
                                                </div>
                                            )}
                                    </dummy>
                                ))}
                            {state.document.flags && (
                                <AdminFormBlockAsTabLang
                                    setDisplayedLang={actions.setDisplayedLang}
                                    document={state.document}
                                    original={state.document.original}
                                    onclick={actions.updateDocument}
                                    kpiList={state.kpiList}
                                    dataTags={state.dataTags}
                                    langs={state.document.langs}
                                    formSubmitted={state.formSubmitted}
                                    onncreate={actions.updateDocument}
                                    customer={props.customer}
                                    editType={state.editType}
                                ></AdminFormBlockAsTabLang>
                            )}
                            {state.document.tabs &&
                                state.originaldocument !== undefined &&
                                state.originaldocument !== null && (
                                    <dummy>
                                        {Array.isArray(state.document.tabs) ? (
                                            <dummy>
                                                {state.document.tabs.map(
                                                    (m, idx) => (
                                                        <AdminFormBlockAsTab
                                                            key={'tabs-' + idx}
                                                            document={{
                                                                tabs: m,
                                                            }}
                                                            originaldocument={
                                                                state.originaldocument
                                                            }
                                                            sendFormAndRedirect={
                                                                actions.sendFormAndRedirect
                                                            }
                                                            deleteEntryAndUpdate={
                                                                actions.deleteEntryAndUpdate
                                                            }
                                                            customer={
                                                                props.customer
                                                            }
                                                        ></AdminFormBlockAsTab>
                                                    )
                                                )}
                                            </dummy>
                                        ) : (
                                            <AdminFormBlockAsTab
                                                key="tabs"
                                                document={state.document}
                                                originaldocument={
                                                    state.originaldocument
                                                }
                                                sendFormAndRedirect={
                                                    actions.sendFormAndRedirect
                                                }
                                                deleteEntryAndUpdate={
                                                    actions.deleteEntryAndUpdate
                                                }
                                                customer={props.customer}
                                            ></AdminFormBlockAsTab>
                                        )}
                                    </dummy>
                                )}
                            {state.document.scenarios && (
                                <AdminFormScenarios
                                    name="scenarios"
                                    document={state.document}
                                    onclick={actions.updateDocument}
                                    kpiList={state.kpiList}
                                    dataTags={state.dataTags}
                                    formSubmitted={state.formSubmitted}
                                    onncreate={actions.updateDocument}
                                    customer={props.customer}
                                ></AdminFormScenarios>
                            )}
                        </div>

                        <Form.Group classes="btzForm-btzFooter btzForm-btzFooter-btzSubmits">
                            <Button
                                primary
                                flat
                                cancel
                                onclick={() => {
                                    window.location.href = state.backUrl
                                }}
                            >
                                {t('Annuler')}
                            </Button>
                            <Button
                                primary
                                flat
                                active
                                loading={state.formIsSubmitting}
                                onclick={actions.sendForm}
                            >
                                {t('Sauvegarder')}
                            </Button>
                        </Form.Group>
                    </Form>
                )}
                {state.previewEnable && (
                    <div
                        class="btzIframeWrapper"
                        style={{
                            display: state.previewDisplayed ? 'block' : 'none',
                        }}
                    >
                        <div
                            class="btzIframeWrapper-close"
                            onclick={() => {
                                actions.onClosePreviewForm()
                            }}
                        ></div>
                        <iframe
                            id="myFrame"
                            name="frame-id"
                            src={APP_FRONT_WEB_URL + '/adminpreview'}
                        ></iframe>
                    </div>
                )}
            </div>
        ),
    'adminform'
)

export { AdminForm }
