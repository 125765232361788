import { t } from '@app/utils'
import * as constants from '@app/constants'
import { User, Consent, Settings } from '@app/api'
import { addCSRFToken, deepCopy } from '@app/core'
import { getDefaultPhonePrefix } from './utils'

var userThemes = deepCopy(constants.custo.CARDS_TYPES)
for (var usert in userThemes) {
    if (
        constants.custo.CARDS_TYPES_USER_IGNORE.indexOf(userThemes[usert].key) >
        -1
    ) {
        delete userThemes[usert]
    }
}

const user_NURSE = {
    filter: ['customer', 'licence', 'registerStep'],
    search: { label: 'ID, KPI, Soignant' },
    tableHead: [
        { key: 'lastName', label: 'nom' },
        { key: 'firstName', label: 'prénom' },
        { key: 'email', label: 'email' },
        { key: 'id', label: 'licence-id' },
        { key: 'customer', label: 'établissement' },
        { key: 'version', label: 'version' },
        { key: 'connexion', label: 'connexion' },
    ],
    tableOptions: [
        {
            key: 'edit',
            label: 'Éditer',
            kind: 'web',
            action: addCSRFToken('/users/nurses/:id'),
        },
        {
            key: 'cleartimelineUser',
            label: 'Vider la timeline',
            kind: 'api',
            action: User.clearTimelineUser,
        },
        {
            key: 'resiliateNurse',
            label: 'Résilier',
            kind: 'confirm',
            action: User.resiliateUser,
        },
        {
            key: 'deleteNurse',
            label: 'Supprimer',
            kind: 'confirm',
            action: User.deleteUser,
        },
    ],
    tableAllOptions: {
        label: 'SOIGNANTS SÉLECTIONNÉS',
        list: [
            {
                key: 'cleartimelineUser',
                label: 'Vider la timeline',
                kind: 'api',
                action: User.clearTimelineUser,
            },
            {
                key: 'deleteNurse',
                label: 'Résilier',
                kind: 'confirm',
                action: User.resiliateUser,
            },
        ],
    },
    header: ['Configurer un soignant'],
    form: {
        'id': {
            field: 'hidden',
            value: '',
        },
        'template': {
            field: 'hidden',
            value: 'nurses',
        },
        'status': {
            name: 'status',
            type: 'int',
            label: 'État',
            field: 'radio',
            choice: {
                Actif: 'USER_ACTIVE',
                Inactif: 'USER_INACTIVE',
            },
            value: 'USER_ACTIVE',
            display: {
                mode: 'full',
                border: 'bottom',
            },
            dependsOn: {
                field: 'roles',
                value: 'ROLE_DOCTOR',
            },
        },
        'lname': {
            name: 'lname',
            type: 'string',
            label: "Nom d'exercice",
            field: 'text',
            mandatory: true,
            placeholder: "Renseignez ici le nom d'exercice de l‘utilisateur",
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: "Veuillez renseigner le nom d'exercice du soignant",
                i18nMessage: t(
                    "Veuillez renseigner le nom d'exercice du soignant"
                ),
            },
            display: {
                mode: 'half',
            },
        },
        'fname': {
            name: 'fname',
            type: 'string',
            label: "Prénom d'exercice",
            field: 'text',
            placeholder: "Renseignez ici le prénom d'exercice de l‘utilisateur",
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: "Veuillez renseigner le prénom d'exercice du soignant",
                i18nMessage: t(
                    "Veuillez renseigner le prénom d'exercice du soignant"
                ),
            },
            display: {
                mode: 'half',
            },
        },
        'practitionerProfession': {
            name: 'practitionerProfession',
            type: 'string',
            label: 'Profession',
            field: 'dropdown',
            placeholder: '',
            list: constants.custo.PRACTITIONER_PROFESSIONS,
            value: '',
            display: {
                mode: 'half',
            },
        },
        'practitionerQualification': {
            name: 'practitionerQualification',
            type: 'string',
            label: 'Spécialité',
            field: 'dropdown',
            placeholder: '',
            list: constants.custo.PRACTITIONER_QUALIFICATIONS,
            value: '',
            forceHidden: false,
            display: {
                mode: 'half',
            },
        },
        'practitionerSpecialty': {
            name: 'practitionerSpecialty',
            type: 'string',
            label: 'Spécialité',
            field: 'text',
            placeholder: '',
            value: '',
            forceHidden: true,
            display: {
                mode: 'half',
            },
        },
        'practitionerCategory': {
            name: 'practitionerCategory',
            type: 'string',
            label: 'Catégorie',
            field: 'dropdown',
            placeholder: '',
            list: constants.custo.PRACTITIONER_CATEGORIES,
            value: 'Professionnel en exercice',
            display: {
                mode: 'full',
            },
        },
        'practitionerExerciseMode': {
            name: 'practitionerExerciseMode',
            type: 'string',
            label: 'Statut',
            field: 'dropdown',
            placeholder: '',
            list: constants.custo.PRACTITIONER_STATUS,
            value: '',
            display: {
                mode: 'half',
            },
        },
        'registeredInRpps': {
            name: 'registeredInRpps',
            type: 'boolean',
            label: 'Soignant référencé RPPS/ADELI',
            field: 'radio',
            choice: {
                Oui: true,
                Non: false,
            },
            placeholder: '',
            value: true,
            display: {
                mode: 'half',
            },
        },
        'monitoringOperator': {
            name: 'monitoringOperator',
            type: 'boolean',
            label: 'Operateur de Telesurveillance',
            field: 'radio',
            choice: {
                Oui: true,
                Non: false,
            },
            placeholder: '',
            value: false,
            display: {
                mode: 'half',
            },
        },
        'ssos': {
            name: 'ssos',
            substitute: 'rpps',
            type: 'string',
            label: 'Identifiant national : RPPS /ADELI',
            field: 'ssos',
            placeholder: '',
            value: [],
            beforeSend: {
                ifempty: null,
                ifarray: 'id',
            },
            display: {
                mode: 'half',
                border: 'bottom',
            },
            dependsOn: {
                field: 'roles',
                value: 'ROLE_DOCTOR',
            },
        },
        'practitionerMultipleRPPS': {
            name: 'practitionerMultipleRPPS',
            type: 'boolean',
            label: 'Ce médecin est actif dans plusieurs centres',
            field: 'radio',
            choice: {
                Oui: true,
                Non: false,
            },
            placeholder: '',
            value: false,
            display: {
                mode: 'half',
            },
        },
        'practitionerAMCode': {
            name: 'practitionerAMCode',
            type: 'int',
            label: 'N° assurance maladie',
            field: 'int',
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'exact-length/9',
                message: 'Veuillez renseigner un numéro à 9 chiffres',
                i18nMessage: t('Veuillez renseigner un numéro à 9 chiffres'),
            },
            placeholderInner: '9 caractères numériques',
            beforeSend: {
                ifempty: 'remove',
            },
            display: {
                mode: 'half',
            },
        },
        'rppsadeli': {
            name: 'rppsadeli',
            type: 'int',
            label: 'Type RPPS / ADELI',
            field: 'dropdown',
            readonly: true,
            placeholder: 'RPPS ou ADELI',
            list: {
                0: { key: 0, value: 'ADELI' },
                1: { key: 8, value: 'RPPS' },
            },
            value: '',
            sending: false,
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'phonePrefix': {
            name: 'phonePrefix',
            type: 'string',
            label: 'Préfix téléphonique',
            field: 'dropdown',
            placeholder: '',
            list: constants.custo.PHONE_PREFIX,
            value: getDefaultPhonePrefix('+33'),
            validation: {
                mandatory: true,
                notmandatory: ['unicancer', 'weprom', 'uroconnect'],
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez sélectionner un prefix téléphonique',
                i18nMessage: t('Veuillez sélectionner un prefix téléphonique'),
            },
            postUpdate: {
                reset: [{ key: 'phone', value: '' }],
            },
            display: {
                mode: 'half',
            },
        },
        'phone': {
            name: 'phone',
            type: 'phone',
            label: 'Téléphone',
            field: 'phone',
            value: '',
            validation: {
                mandatory: true,
                notmandatory: ['unicancer', 'weprom', 'uroconnect'],
                isValid: false,
                type: 'is-phone',
                message: 'Veuillez renseigner le téléphone de l‘utilisateur',
                i18nMessage: t(
                    'Veuillez renseigner le téléphone de l‘utilisateur'
                ),
            },
            display: {
                mode: 'half',
            },
        },
        'email': {
            name: 'email',
            type: 'string',
            label: 'Email de communication',
            field: 'email',
            placeholder:
                'Renseignez ici l‘email de communication de l‘utilisateur',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'is-email',
                message:
                    'Veuillez renseigner l‘email de communication de l‘utilisateur',
                i18nMessage: t(
                    'Veuillez renseigner l‘email de communication de l‘utilisateur'
                ),
            },
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'practitionerEmail': {
            name: 'practitionerEmail',
            type: 'string',
            label: 'Email sécurisé',
            field: 'email',
            placeholder: 'Renseignez ici l‘email sécurisé de l‘utilisateur',
            value: '',
            validation: {
                mandatory: false,
                isValid: false,
                type: 'is-email',
                message:
                    'Veuillez renseigner l‘email sécurisé de l‘utilisateur',
                i18nMessage: t(
                    'Veuillez renseigner l‘email sécurisé de l‘utilisateur'
                ),
            },
            display: {
                mode: 'half',
                border: 'bottom',
            },
        },
        'locale': {
            name: 'locale',
            type: 'int',
            label: 'Langue préféré',
            field: 'dropdown',
            placeholder: 'Faites dérouler la liste',
            list: constants.custo.LANGUAGES,
            value: 'fr',
            display: {
                mode: 'half',
            },
        },
        'timezone': {
            name: 'timezone',
            type: 'timezone',
            label: 'Fuseau horaire',
            field: 'dropdown',
            placeholder: 'Aucun',
            searchbox: true,
            list: 'dynamic',
            simplecall: true,
            apicall: Settings.getTimezones,
            apiparam: '?rproSupported=1',
            value: 'Europe/Paris',
            display: {
                mode: 'half',
            },
        },
        'customer.parent': {
            name: 'customer.parent',
            type: 'customer',
            label: 'Établissement mère',
            field: 'dropdown',
            list: 'dynamic',
            sending: false,
            placeholder: '',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez sélectionner un établissement mère',
                i18nMessage: t('Veuillez sélectionner un établissement mère'),
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'roles',
                value: 'ROLE_DOCTOR',
            },
        },
        'customerFille': {
            name: 'customer',
            type: 'customer',
            substitute: 'customer',
            label: 'Établissement fille',
            field: 'dropdown',
            list: 'dynamic',
            placeholder: '',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez sélectionner un établissement fille',
                i18nMessage: t('Veuillez sélectionner un établissement fille'),
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'roles',
                value: 'ROLE_DOCTOR',
            },
            // 'update': {'notnull': {key: 'roles', value: 'ROLE_DOCTOR'}, 'null': {key: 'roles', value: 'ROLE_MEDICAL_SUPERVISOR'}},
        },
        'customer': {
            name: 'customer',
            type: 'customer',
            label: 'Établissement mère',
            field: 'dropdown',
            list: 'dynamic',
            placeholder: '',
            value: '',
            validation: {
                mandatory: true,
                isValid: false,
                type: 'not-empty',
                message: 'Veuillez sélectionner un établissement mère',
                i18nMessage: t('Veuillez sélectionner un établissement mère'),
            },
            display: {
                mode: 'half',
            },
            dependsOn: {
                field: 'roles',
                value: ['ROLE_MEDICAL_SUPERVISOR', 'ROLE_MEDICAL_PROMOTER'],
                no: 'ROLE_DOCTOR',
            },
            // 'update': {'notnull': {key: 'roles', value: 'ROLE_DOCTOR'}, 'null': {key: 'roles', value: 'ROLE_MEDICAL_SUPERVISOR'}},
        },
        'roles': {
            name: 'roles',
            type: 'array',
            label: 'Type de compte',
            field: 'dropdown',
            placeholder: '',
            list: {
                0: { key: 'ROLE_MEDICAL_SUPERVISOR', value: 'Soignant' },
                1: { key: 'ROLE_DOCTOR', value: 'Docteur' },
                2: { key: 'ROLE_MEDICAL_PROMOTER', value: 'Promoteur' },
            },
            value: 'ROLE_DOCTOR',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'connexion': {
            name: 'connexion',
            type: 'date',
            label: 'Dernière visite',
            field: 'readonly',
            withHour: true,
            additionalFields: ['withHour', 'withInvit', 'withUnlock'],
            placeholder: { day: 'jj', week: 'mm', year: 'aaaa' },
            value: '',
            sending: false,
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'security.blockedLogin': {
            name: 'security.blockedLogin',
            type: 'string',
            label: 'Statut compte',
            field: 'verify-with-query',
            verifyQuery: User.unlockAccount,
            verifyParam: 'id',
            verifyLabel: 'Débloquer le compte',
            verifySuccess: 'Compté débloqué.',
            placeholder: '',
            readonly: true,
            sending: false,
            value: '',
            display: {
                mode: 'full',
            },
        },
        'agreements': {
            name: 'agreements',
            type: 'array',
            label: 'Consentement',
            field: 'display-from-query',
            displayQuery: Consent.getUserLegalAgreements,
            displayParam: [
                { key: 'id', kind: 'dynamic' },
                { key: 'doctor', kind: 'static' },
            ],
            placeholder: '',
            readonly: true,
            sending: false,
            value: '',
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'isTestData': {
            name: 'isTestData',
            type: 'boolean',
            label: 'Labelliser comme donnée de test',
            field: 'radio',
            choice: {
                Oui: true,
                Non: false,
            },
            placeholder: '',
            value: false,
            display: {
                mode: 'full',
                border: 'bottom',
            },
        },
        'email-control': {
            name: 'email-control',
            type: 'int',
            label: 'Activer/Désactiver tous les emails alerte',
            field: 'radio',
            sending: false,
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: 1,
            dynValue: {
                dependsOn: [
                    'settings.DOCTORSURVEYALERT',
                    'settings.MEDICALTEAMMEMBERUSERMEDICALSTUDYSURVEYALERTNOTCLOSED',
                    'settings.MEDICALTEAMMEMBEREMPTYUSERMEDICALSTUDYSURVEY',
                ],
                actsOn: [
                    'settings.DOCTORSURVEYALERT',
                    'settings.MEDICALTEAMMEMBERUSERMEDICALSTUDYSURVEYALERTNOTCLOSED',
                    'settings.MEDICALTEAMMEMBEREMPTYUSERMEDICALSTUDYSURVEY',
                ],
            },
        },
        'settings.DOCTORSURVEYALERT': {
            name: 'settings.DOCTORSURVEYALERT',
            type: 'int',
            label: 'Email alerte questionnaire',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: 1,
            defaultValue: 0,
        },
        'settings.MEDICALTEAMMEMBERUSERMEDICALSTUDYSURVEYALERTNOTCLOSED': {
            name: 'settings.MEDICALTEAMMEMBERUSERMEDICALSTUDYSURVEYALERTNOTCLOSED',
            type: 'int',
            label: 'Email alerte non traitée',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: 1,
            defaultValue: 0,
        },
        'settings.MEDICALTEAMMEMBEREMPTYUSERMEDICALSTUDYSURVEY': {
            name: 'settings.MEDICALTEAMMEMBEREMPTYUSERMEDICALSTUDYSURVEY',
            type: 'int',
            label: 'Email questionnaire non répondu',
            field: 'radio',
            choice: {
                Oui: 1,
                Non: 0,
            },
            value: 1,
            defaultValue: 0,
        },
        'ssosNabla': {
            name: 'ssos',
            searchedValue: 'nabla-suborg',
            type: 'string',
            label: 'Nabla sous orga ID',
            field: 'verify-with-query',
            verifyQuery: User.submitNablaSuborgId,
            verifyParam: null,
            verifyLabel: 'Soumettre cet ID',
            verifySuccess: 'Le Nabla sous orga ID a bien été ajouté.',
            verifyParamOptParams: ['id'],
            validation: {
                mandatory: false,
                isValid: false,
                type: 'not-empty',
                verifiedMessage: 'Le Nabla sous orga ID  est invalide',
                i18nMessage: t('Le Nabla sous orga ID  est invalide'),
            },
            placeholder: '',
            readonly: false,
            sending: false,
            value: '',
            display: {
                mode: 'full',
            },
            dependsOn: {
                documentExist: true,
            },
        },
    },
}

export { user_NURSE }
