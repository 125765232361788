import { isValidPhone } from '.'

// require('@app/__tests__/setup')

describe('isValidPhone', () => {
    test('should failed when phone is invalid and prefix undefined', async () => {
        expect(isValidPhone(false)).toBe(false)
    })

    test('should failed when phone is undefined and prefix is invalid', async () => {
        expect(isValidPhone(undefined, false)).toBe(false)
    })
  
    test('should success when phone and prefix are valid', async () => {
        expect(isValidPhone('0644940582', '+33')).toBe(true)
    })

    test('should failed when phone is invalid and prefix is valid', async () => {
        expect(isValidPhone('06449405825', '+33')).toBe(false)
    })
})
