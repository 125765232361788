import { queueQueries } from '@app/api/controlleur'
import { settings } from '@app/core/settings'

const { APP_API_URL } = settings

function updateDoctorNursesTeam(data) {
    return queueQueries(
        `${APP_API_URL}/medical/supervisor`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        data
    )
}

function addTrustIP(data) {
    return queueQueries(
        `${APP_API_URL}/admin/trusted-ip`,
        'POST',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { trustedIps: data }
    )
}

function removeTrustIP(data) {
    return queueQueries(
        `${APP_API_URL}/admin/trusted-ip`,
        'DELETE',
        'application/json',
        true,
        true,
        true,
        true,
        true,
        { trustedIps: data }
    )
}

function getTrustIP() {
    return queueQueries(`${APP_API_URL}/admin/trusted-ip`, 'GET')
}

export const Operations = {
    updateDoctorNursesTeam,
    addTrustIP,
    removeTrustIP,
    getTrustIP,
}
